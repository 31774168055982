import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Phone, MessageCircle } from "react-feather";
import { FiRefreshCw } from "react-icons/fi";
import TrackbeeCommunityLogo from "../assets/images/Trackbee community Logo.png";
// const API_BASE_URL = "http://localhost:3000";
import { API_BASE_URL, REACT_APP_SECRET_KEY } from "./env";
import "../styles/Style.css";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust as needed */
  background-color: #dee2e6;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Form = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* Adjust as needed */
  @media (max-width: 768px) {
    width: 90%; /* Adjust as needed */
  }
`;

const Title = styled.h5`
  margin-bottom: 20px;
  margin-left: 32px;
  font-weight: bold;
  color: #363b5f;
  @media (max-width: 768px) {
    margin-left: 0; /* Adjust as needed */
    text-align: center;
  }
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: #363b5f;
`;

const Input = styled.input`
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;
`;

const Button = styled.button`
  background-color: ${(props) => (props.disabled ? "#6c757d" : " #4796c5")};
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 100%;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#6c757d" : "#363B5F")};
  }
`;

const MessageContainer = styled.div`
  margin-top: 16px;
  padding: 10px;
  border-radius: 8px;
  background-color: #363b5f; /* Green color, you can customize */
  color: white;
  text-align: center;
`;

const BackLink = styled(Link)`
  color: #363b5f;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    color: #4796c5;
  }
`;

const VerifyYourMobileNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [verification, setVerification] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [showOtpField, setShowOtpField] = useState(false);
  const [disablePhoneNumberInput, setDisablePhoneNumberInput] = useState(false);

  useEffect(() => {
    let timer;

    if (disableButton && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisableButton(false);
      setCountdown(0);
      setVerificationStatus("");
      setVerification("");
      setOtp("");
    }

    return () => clearTimeout(timer);
  }, [disableButton, countdown]);

  const resetCountdownAndButton = () => {
    setDisableButton(false);
    setCountdown(60);
  };

  const sendOTP = async () => {
    setShowOtpField(true);
    resetCountdownAndButton();
    try {
      setDisableButton(true);
      const response = await axios.post(API_BASE_URL + "/auth/send_otp", {
        phone_number: phoneNumber,
      });

      if (response.data.success) {
        // console.log(response.data.message);
      } else {
        setShowOtpField(false);
        console.error(
          "Failed to send OTP. Server response:",
          "Too many attempts. Please try again after sometimes."
        );

        if (response.data.message.includes("Too many attempts")) {
          const minutesRemaining = parseInt(
            response.data.message.match(/\d+/)[0],
            10
          );

          // Reset the countdown and re-enable the "Send OTP" button
          resetCountdownAndButton();

          // Use setTimeout to wait for the resetCountdownAndButton to complete
          if (minutesRemaining > 0) {
            setVerificationStatus(
              `Too many attempts. Please try again after sometimes`
            );

            // Start a timeout to reload the page after 15 minutes
            const reloadTimeout = setTimeout(() => {
              window.location.reload();
            }, 15 * 60 * 1000); // 15 minutes in milliseconds

            // Clear the timeout if the component unmounts or the conditions change
            return () => clearTimeout(reloadTimeout);
          } else {
            setVerificationStatus("");
          }
        } else {
          // Handle other error cases here
          setVerificationStatus("Failed to send OTP");

          // Enable the "Send OTP" button
          setDisableButton(false);
        }
      }
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      setVerificationStatus("Error sending OTP");

      // Reset the countdown and re-enable the "Send OTP" button
      resetCountdownAndButton();
    }
  };

  const verifyOTP = async () => {
    try {
      const response = await axios.post(API_BASE_URL + "/auth/verify_otp", {
        phone_number: phoneNumber,
        entered_otp: otp,
      });
      if (response.data.success) {
        // console.log(response.data.message);
        setVerificationStatus(response.data.message);
        checkApplicantExistence();
      } else {
        console.error(
          "Failed to verify OTP. Server response:",
          response.data.message
        );
        setVerificationStatus("Failed to Verify OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error.message);
      setVerificationStatus("Error Verifying OTP");
    }
  };

  const navigate = useNavigate();

  const checkApplicantExistence = async () => {
    try {
      // Validation: Check if the phone number is valid
      if (!phoneNumber) {
        setVerificationStatus("Please enter a valid phone number.");
        return;
      }
      setDisablePhoneNumberInput(true);
      // setLoading(true); // Set loading state to true

      const response = await axios.get(
        API_BASE_URL + `/auth/check_applicant_existence/${phoneNumber}`
      );

      if (response.data.success) {
        // console.log(response.data.message);
        setShowOtpField(false);
        if (
          response.data.message[0].self_declaration === "0" ||
          response.data.message[0].self_declaration === null
        ) {
          // console.log(response.data.message[0].encrypted_code)
          const encrypted_code = response.data.message[0].encrypted_code;
          navigate("/editProfileFormUser/" + encrypted_code); // Pass encrypted_code
        } else {
          checkPhoneNumberVerificationStatus();
        }
      } else {
        console.error(
          "Applicant does not exist. Server response:",
          response.data.message
        );

        // If the applicant doesn't exist, send OTP
        // sendOTP();
        navigate("/profileForm", { state: { phoneNumber } }); // Pass phoneNumber as state
      }
    } catch (error) {
      console.error("Error checking applicant existence:", error.message);
      setVerificationStatus("Error checking applicant existence");
    } finally {
      // setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  const checkPhoneNumberVerificationStatus = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL + `/auth/check_verification_status/${phoneNumber}`
      );
      if (response.data.success) {
        // console.log(response.data.message);
        if (response.data.message[0].verified === "1") {
          setVerificationStatus("");
          if (response.data.message[0].give_permission === "1") {
            setVerification(
              <p>
                Please visit{"-> "}
                <a href="/publicDashboard" target="_blank">
                  Public Dashboard
                </a>
              </p>
            );
          } else {
            setVerificationStatus(
              "You are a verified member.If you have any further questions or concerns, feel free to contact us."
            );
          }
        }
      } else {
        console.error(
          "Phone number not verified. Server response:",
          response.data.message
        );
        setVerificationStatus(
          "Profile verification is in progress, you will receive a call from a TrackBee Community executive soon."
        );
      }
    } catch (error) {
      console.error("Error checking verification status:", error.message);
    }
  };

  // const handleLinkClick = () => {
  //   return <Link to="/publicDashboard" target="_blank">Public Dashboard</Link>
  // };

  // const checkPhoneNumberInEnumerators = async () => {
  //   try {
  //     const response = await axios.get(
  //       `http://localhost:3000/auth/check_phone_number_in_enumerators/${phoneNumber}`
  //     );
  //     if (response.data.success) {
  //       console.log(response.data.response);
  //       if (response.data.response.give_permission === "1") {
  //         setVerificationStatus("");
  //         setVerification(
  //           <p>
  //             You have already enrolled yourself once. Please visit{" "}
  //             <a href="#" onClick={handleLinkClick}>
  //               http://localhost:3001/publicDashboard
  //             </a>
  //           </p>
  //         );
  //       } else {
  //         setVerification("");
  //         setVerificationStatus(
  //           "You have already Enrolled yourself once. If you have any further questions or concerns, feel free to contact us."
  //         );
  //       }
  //     } else {
  //       const responseVerificationStatus = await axios.get(
  //         `http://localhost:3000/auth/check_verification_status/${phoneNumber}`
  //       );

  //       if (responseVerificationStatus.data.success) {
  //         console.log(responseVerificationStatus.data.message);

  //         if (responseVerificationStatus.data.message[0].verified === "1") {
  //           navigate("/enrollmentForm", {
  //             state: responseVerificationStatus.data.message[0],
  //           });
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error checking phone number in enumerators:",
  //       error.message
  //     );
  //   }
  // };

  // const clearVerificationStatus = () => {
  //   setVerificationStatus("");
  // };

  return (
    <Container>
      <div className="login-logo">
        <Link to="/">
          <img
            src={TrackbeeCommunityLogo}
            width="150px"
            alt="TrackbeeCommunityLogo"
          />
        </Link>
      </div>
      <Form>
        <Title>VERIFY YOUR MOBILE NUMBER</Title>
        <Label htmlFor="phoneNumber">Phone Number:</Label>
        <div className="input-container position-relative">
          <Phone className="fea icon-sm icons position-absolute start-10 top-50 translate-middle-y" />
          <Input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            disabled={disablePhoneNumberInput}
            style={{ paddingLeft: "35px" }} // Adjust the padding as needed
            className="form-control rounded"
          />
        </div>
        {!showOtpField && (
          <Button
            type="button"
            onClick={sendOTP}
            disabled={
              disableButton || verificationStatus.includes("Too many attempts")
            }
          >
            {disableButton ? "Submit" : "Submit"}
          </Button>
        )}
        {showOtpField && (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {countdown > 0 &&
                !verificationStatus.includes("Too many attempts") && (
                  <p className="me-2">Resend OTP in {countdown} seconds</p>
                )}
            </div>

            <Button
              type="button"
              onClick={() => {
                resetCountdownAndButton();
                sendOTP(); // Call sendOTP when Resend is clicked
              }}
              disabled={
                disableButton ||
                verificationStatus.includes("Too many attempts")
              }
              style={{
                fontSize: "16px",
                width: "90px",
                height: "40px",
                display: "flex",
                alignItems: "center",
              }} // Adjust as needed
            >
              <FiRefreshCw size={18} className="me-1" />
              Resend
            </Button>
          </div>
        )}

        {showOtpField && (
          <>
            <Label htmlFor="otp">Enter OTP:</Label>
            <div className="input-container position-relative">
              <MessageCircle className="fea icon-sm icons position-absolute start-10 top-50 translate-middle-y" />
              <Input
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter received OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                style={{ paddingLeft: "35px" }} // Adjust the padding as needed
                className="form-control rounded"
              />
            </div>
            <Button type="button" onClick={verifyOTP}>
              Verify
            </Button>
          </>
        )}

        {verificationStatus && (
          <MessageContainer>{verificationStatus}</MessageContainer>
        )}

        {verification && (
          <>
            <MessageContainer>{verification}</MessageContainer>
          </>
        )}
      </Form>
      <BackLink to="/" style={{ fontWeight: "bold", marginTop: "0px" }}>
        Back to Home
      </BackLink>
    </Container>
  );
};

export default VerifyYourMobileNumber;
