import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import DataTable from "react-data-table-component";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
import { format } from "date-fns";
// import { useHistory } from "react-router-dom";
// import { CSVLink } from "react-csv";
import { FilePlus, Plus, PlusCircle, PlusSquare, Search, UserPlus } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// const API_BASE_URL = 'http://localhost:3000';
import {API_BASE_URL, REACT_APP_SECRET_KEY} from "./env";
import { useCookies } from 'react-cookie';
import CryptoJS from "crypto-js";

const JobPosts = () => {

  const navigate = useNavigate();

  // axios.defaults.withCredentials = true;
  // useEffect(() => {
  //   axios
  //     .get(API_BASE_URL + "/auth/verify")
  //     .then((result) => {
  //       if (result.data.Status) {
  //         if (result.data.role === "admin") {
  //           navigate("/jobPosts");
  //         }
  //       } else {
  //         navigate("/login");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const [cookies] = useCookies(['token']);

  useEffect(() => {
    if (!cookies.token) {
      navigate("/login");
    } else {
      try {
        const decryptedToken = CryptoJS.AES.decrypt(cookies.token, REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        if (!decryptedToken) {
          throw new Error("Token decryption failed");
        }
      } catch (error) {
        console.error("Token decryption failed:", error);
        navigate("/login");
      }
    }
  }, [cookies.token, navigate]);

  const [isHovered, setIsHovered] = useState(false);

  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValues, setSearchValues] = useState([]);

  const confirmDeleteJobPost = (e, jobId) => {
    e.preventDefault();
  
    // Display confirmation dialog
    const isConfirmed = window.confirm("Are you sure, you want to delete this job post?");
    
    // If confirmed, call handleDeleteJobPosts
    if (isConfirmed) {
      handleDeleteJobPosts(jobId);
    }
  };

  const handleDeleteJobPosts = (jobId) => {
    // event.preventDefault();
    // console.log("Deleting applicant with ID:", applicantId);
    axios
      .delete( API_BASE_URL + "/auth/deleteJobPosts/" + jobId)
      .then((res) => {
        if (res.data.Status) {
          window.location.reload("/jobPosts");
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get( API_BASE_URL + "/auth/getJobPosts")
      .then((res) => {
        if (res.data.Status) {
          // console.log(res.data.Result);
          setValues(res.data.Result);
          setSearchValues(res.data.Result);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const result = values.filter((e) => {
  //     return e.header.toLowerCase().match(search.toLocaleLowerCase());
  //   });
  //   setSearchValues(result);
  // }, [search]);

  useEffect(() => {
    const result = values.filter((row) => {
      return Object.values(row).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    });
    setSearchValues(result);
  }, [search, values]);

  const formatDate = (inputDate) => {
    if (inputDate === null) {
      return "N/A"; // Or any other placeholder for null dates
    }

    try {
      const date = new Date(inputDate);
      if (isNaN(date.getTime())) {
        return "Invalid Date"; // Handle invalid date strings
      }

      const formattedDate = format(date, "do, MMM yyyy");

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Error"; // Handle unexpected errors
    }
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "50px", // Set the height as needed
      },
    },
    headCells: {
      style: {
        fontWeight: "bold", // Make the heading text bold
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        fontSize: "14px", // Adjust the font size as needed
        height: "100px",
      },
    },
  };

  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "10px",
    },
    {
      name: "Header",
      cell: (row) => row.header,
      sortable: true,
      maxWidth: "500px",
      style: {
        maxWidth: "500px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },

    {
      name: "Contacts",
      cell: (row) => (
        <div>
          <b>Phone: </b>
          {row.phone}
          <br />
          <span>
            <b>Email: </b>
            {row.email}
          </span>
        </div>
      ),
      sortable: true,
      maxWidth: "300px",
    },
    {
      name: "Visible",
      cell: (row) => (row.status === "1" ? "Yes" : "No"),
      sortable: true,
      maxWidth: "100px",
      center: true,
    },
    // {
    //   name: "Location",
    //   cell: (row) => (
    //     <div>
    //       <b>State: </b>
    //       {stateMapping[row.state]}
    //       <br />
    //       <span>
    //         <b>City: </b>
    //         {capitalizeFirstLetter(row.city)}
    //       </span>
    //     </div>
    //   ),
    //   sortable: true,
    //   maxWidth: "160px",
    // },
    // {
    //   name: "Experience",
    //   cell: (row) => (
    //     <div>
    //       <b>Year of Experience: </b>
    //       {row.experience}
    //       <br />
    //       <span>
    //         <b>Highest Education: </b>
    //         {EducationMapping[row.education]}
    //       </span>
    //       <br />
    //       <span>
    //         <b>Use Trackbee Mobile Application: </b>
    //         {row.previous_trackbee_experience === "1" ? "Yes" : "No"}
    //       </span>
    //     </div>
    //   ),
    //   maxWidth: "260px",
    // },
    {
      name: "Posted on",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {formatDate(row.posted_on)}
        </div>
      ),
      sortable: true,
      maxWidth: "200px",
      center: true,
    },
    // {
    //   name: "Is_Verified",
    //   cell: (row) => (
    //     <>
    //       <div>
    //         <input
    //           type="checkbox"
    //           checked={row.verified === "1"}
    //           disabled={row.verified === "1"}
    //           onChange={() =>
    //             handleVerificationChange(row.id, !row.verified === "1")
    //           }
    //         />
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    //   center: true,
    //   maxWidth: "200px",
    // },
    {
      name: "ACTION",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <Link
            to={"/editJobPosts/" + row.id}
            className="action-link me-3 "
          >
            <i className="fas fa-edit"></i>
          </Link>
          <Link
            to={"/deleteApplicant/" + row.id}
            onClick={(e) => confirmDeleteJobPost(e, row.id)}
            className="action-link text-danger"
          >
            <i className="fas fa-trash"></i>
          </Link>
        </div>
      ),
      sortable: true,
      center: true,
      maxWidth: "200px",
    },
  ];

  return (
    <div>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12">
            <div className="card shadow">
              <div
                className="card-body p-1"
                style={{ backgroundColor: "#5e627e" }}
              >
                <h5 className="card-title text-light mt-1">Job Post list</h5>
                {/* Add Button */}
                <Link
                  to="/addJobPosts"
                  className="btn btn-sm btn-flat float-right mr-2"
                  style={{
                    backgroundColor: isHovered ? "#3A90BF" : "#C6DCF9",
                    transition: "background-color 0.3s ease", // Smooth transition on color change
                  }}
                  type="button"
                  onMouseEnter={() => setIsHovered(true)} // Set isHovered to true on mouse enter
                  onMouseLeave={() => setIsHovered(false)} // Set isHovered to false on mouse leave
                >
                  <FilePlus className="fea icon-sm icons" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="table-responsive shadow p-3">
              <DataTable
                columns={columns}
                data={searchValues}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="700px"
                customStyles={customStyles}
                // ... other DataTable props
                // selectableRows
                // selectableRowsHighlight
                highlightOnHover
                // actions={
                //   <button className="btn btn-sm btn-info">Download</button>
                // }
                subHeader
                subHeaderComponent={
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <div className="input-group">
                        <span className="input-group-text">
                          <Search size={16} />
                        </span>
                        <input
                          type="text"
                          placeholder="Search here"
                          className="form-control"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                }
                // subHeaderAlign=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPosts;
