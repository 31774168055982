import React from "react";
import {ArrowUp} from "react-feather";

const Footer = () => {
  return (
    <>
      {/* <footer className="footer" style={{ backgroundColor: "#202942" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0">
              <a href="/" className="logo-footer">
                <img src={logolight} style={{ height: 70 }} alt="logo-light" />
              </a>
              <p className="mt-2" style={{ color: "white" }}>
                It is a community of enumerators designed to enhance their
                abilities, connect with peers and clients, and showcase their
                qualifications.
              </p>
              <ul className="list-unstyled social-icon social mb-0 mt-4">
                <li className="list-inline-item">
                  <a href="/" className="rounded">
                    <Facebook className="fea icon-sm fea-social" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="/" className="rounded">
                    <Instagram className="fea icon-sm fea-social" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="/" className="rounded">
                    <Twitter className="fea icon-sm fea-social" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="/" className="rounded">
                    <Linkedin className="fea icon-sm fea-social" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head mt-3">Main Menu</h5>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a
                    href="https://www.trackbee.com/about_us/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    <i className="uil uil-angle-right-b me-1" /> About Trackbee
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.trackbee.com/features/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    <i className="uil uil-angle-right-b me-1" /> Trackbee
                    Features
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.trackbee.com/case_studies/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    <i className="uil uil-angle-right-b me-1" /> Case Studies
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head mt-3">Useful Links</h5>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a
                    href="https://www.trackbee.com/contact_us/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    <i className="uil uil-angle-right-b me-1" /> Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="<?php echo site_url('terms_and_conditions');?>"
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    <i className="uil uil-angle-right-b me-1" /> Terms of
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="<?php echo site_url('privacy_policy');?>"
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    <i className="uil uil-angle-right-b me-1" /> Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h5 className="text-light footer-head mt-3">Newsletter</h5>
              <p className="mt-4" style={{ color: "white" }}>
                Sign up and receive the latest tips via email.
              </p>
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="foot-subscribe foot-white mb-3">
                      <label className="form-label" style={{ color: "white" }}>
                        Enter your email <span className="text-danger">*</span>
                      </label>
                      <div className="form-icon position-relative">
                        <Mail className="fea icon-sm icons" />
                        <input
                          type="email"
                          name="email"
                          id="emailsubscribe"
                          className="form-control bg-light border ps-5 rounded"
                          placeholder="Your email : "
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="d-grid">
                      <input
                        type="submit"
                        id="submitsubscribe"
                        name="send"
                        className="btn btn-light"
                        defaultValue="Subscribe"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer> */}
      <footer className="footer footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="text-sm-start">
                <p className="mb-0">
                  All rights received. © {new Date().getFullYear()} Trackbee
                  Community, an initiative by{" "}
                  <a
                    href="http://trackbee.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-reset"
                  >
                    Trackbee Data Solutions Pvt Ltd.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <a href="#top"  id="back-to-top" className="btn btn-icon btn-light back-to-top"><ArrowUp className="icons"></ArrowUp></a>
    </>
  );
};

export default Footer;
