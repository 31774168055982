import React, { useState, useEffect } from "react";
import HeaderOne from "./HeaderOne";
import FooterOne from "./FooterOne";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import avatar2 from "../assets/front_images/avatar2.png";
import "../styles/Style.css";
import { API_BASE_URL, REACT_APP_SECRET_KEY } from "./env";
import {
  Book,
  Calendar,
  Circle,
  Clock,
  Mail,
  Map,
  MapPin,
  MessageCircle,
  Navigation,
  Octagon,
  Phone,
  User,
} from "react-feather";

// const API_BASE_URL = "http://localhost:3000";

const ProfileForm = () => {
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve phone number from route state
    const { state } = location;
    if (state && state.phoneNumber) {
      setPhoneNumber(state.phoneNumber);
    } else {
      navigate("/verifyYourMobileNumber");
    }
  }, [location, navigate]);

  const startingYear = new Date().getFullYear() - 18;
  const yearOptions = [];

  for (let i = 0; i < 53; i++) {
    const year = startingYear - i;
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const handleImageChange = (e) => {
    setErrors((prevErrors) => ({ ...prevErrors, image: "" }));

    const selectedFile = e.target.files[0];
    setImageName(e.target.files[0]);

    // Check if file type is supported
    if (
      selectedFile &&
      !["image/png", "image/jpeg", "image/jpg"].includes(selectedFile.type)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Only PNG, JPEG, and JPG formats are supported.",
      }));
      return;
    }

    // Check if file size exceeds 1MB
    if (selectedFile && selectedFile.size > 1024 * 1024) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "File size exceeds 1MB limit.",
      }));
      return;
    }

    // Create a temporary image object to check dimensions
    const tempImg = new Image();
    tempImg.onload = function () {
      if (tempImg.width === 512 && tempImg.height === 512) {
        setSelectedImage(URL.createObjectURL(selectedFile));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "Image dimensions should be 512x512 pixels.",
        }));
      }
    };

    if (selectedFile) {
      tempImg.src = URL.createObjectURL(selectedFile);
    }
  };

 

  const generateRandomCode = () => {
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    const randomCode = Array.from(array)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return randomCode;
  };
  const unique_code = generateRandomCode();

  // const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    const match = userAgent.match(/(Chrome|Firefox|Safari|Edge)\/(\S+)/);

    if (match) {
      const browserName = match[1];
      const browserVersion = match[2];
      return `${browserName} | ${browserVersion}`;
    } else {
      return "Unknown Browser";
    }
  };

  const getDeviceInfo = () => {
    const platform = navigator.platform;
    return platform.includes("Win")
      ? "Windows"
      : platform.includes("Android")
      ? "Android"
      : "iOS";
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  //   const browser = getBrowserInfo();
  //   const device = getDeviceInfo();

  //   setValues({
  //     ...values,
  //     [name]: value,
  //     encrypted_code: unique_code,
  //     // phone_number: phoneNumber,
  //     browser: browser,
  //     device: device,
  //     image: imageName,
  //   });

  //   if (name === "year_of_birth") {
  //     const currentYear = new Date().getFullYear();
  //     const selectedYear = parseInt(value);
  //     const age = currentYear - selectedYear;
  //     setValues((prevValues) => ({ ...prevValues, age }));
  //   }
  // };

  const [values, setValues] = useState({
    // ... your other state properties
    survey_methodology: [],
    phone_number: phoneNumber, // Initialize survey_methodology as an empty array
    // ... your other state properties
    districts: [],
    district: "",
  });

  const handleInputChange = (e) => {
    const browser = getBrowserInfo();
    const device = getDeviceInfo();

    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    const newValue = type === "checkbox" ? checked : value;

    if (name === "survey_methodology") {
      // If the input is a checkbox for survey methodology or project done
      setErrors((prevErrors) => ({ ...prevErrors, survey_methodology: "" }));
      setValues((prevState) => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter((item) => item !== value),
      }));
    } else {
      // For other inputs, update the state normally
      setValues((prevState) => ({
        ...prevState,
        [name]: newValue,
        encrypted_code: unique_code,
        phone_number: phoneNumber,
        browser: browser,
        device: device,
        image: imageName,
      }));

      // if (name === "pincode" && newValue) {
      //   axios
      //     .get("http://localhost:3000/auth/get-state/" + newValue)
      //     .then((res) => {
      //       const state_by_pincode = res.data.state;
      //       setValues((prevState) => ({
      //         ...prevState,
      //         state: state_by_pincode,
      //       }));
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching state information:", error);
      //     });
      // }
      if (name === "state" && newValue) {
        axios
          .get(API_BASE_URL + `/auth/get-district/${newValue}`)
          .then((res) => {
            const districts = res.data.Result;
            setValues((prevState) => ({
              ...prevState,
              districts: districts,
            }));
          })
          .catch((error) => {
            console.error("Error fetching district information:", error);
          });
      }

      if (values.have_whatsapp_number === "1") {
        setValues((prevState) => ({
          ...prevState,
          [name]: newValue,
          whatsapp_phone_number: phoneNumber,
        }));
      }
      if (name === "year_of_birth") {
        const currentYear = new Date().getFullYear();
        const selectedYear = parseInt(value);
        const age = currentYear - selectedYear;
        setValues((prevValues) => ({ ...prevValues, age }));
      }
    }
  };

  console.log("values:", values);
  // console.log(values.district);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.name) {
      validationErrors.name = "Please provide input";
    }
    if (
      !values.email_id ||
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        values.email_id.trim()
      )
    ) {
      validationErrors.email_id = "Please provide valid Email id";
    }
    if (!values.phone_number) {
      validationErrors.phone_number = "Please provide input";
    } else if (
      !values.phone_number ||
      !/^[6789]\d{9}$/.test(values.phone_number)
    ) {
      validationErrors.phone_number = "Please provide a valid Mobile number";
    }
    if (
      !values.whatsapp_phone_number ||
      !/^[6789]\d{9}$/.test(values.whatsapp_phone_number)
    ) {
      validationErrors.whatsapp_phone_number =
        "Please provide a valid Mobile number";
    }
    if (!values.gender) {
      validationErrors.gender = "Please provide input";
    }
    if (!values.previous_trackbee_experience) {
      validationErrors.previous_trackbee_experience = "Please provide input";
    }
    if (!values.have_whatsapp_number) {
      validationErrors.have_whatsapp_number = "Please provide input";
    }
    if (!values.proficient_english) {
      validationErrors.proficient_english = "Please provide input";
    }
    if (!values.proficient_hindi) {
      validationErrors.proficient_hindi = "Please provide input";
    }
    if (!values.state) {
      validationErrors.state = "Please provide input";
    }
    if (!values.district) {
      validationErrors.district = "Please provide input";
    }
    if (!values.city) {
      validationErrors.city = "Please provide input";
    }
    if (!values.year_of_birth) {
      validationErrors.year_of_birth = "Please provide input";
    }
    if (!values.education) {
      validationErrors.education = "Please provide input";
    }
    if (!values.pincode) {
      validationErrors.pincode = "Please provide input";
    }
    if (!values.marital_status) {
      validationErrors.marital_status = "Please provide input";
    }
    if (!values.experience) {
      validationErrors.experience = "Please provide input";
    }
    if (!values.age) {
      validationErrors.age = "Please provide input";
    }
    if (!values.join_reason) {
      validationErrors.join_reason = "Please provide input";
    }
    if (!imageName) {
      validationErrors.image = "Please provide input";
    }
    if (!values.self_declaration) {
      validationErrors.self_declaration = "Please provide input";
    }
    if (!values.survey_methodology || values.survey_methodology.length === 0) {
      validationErrors.survey_methodology = "Please provide input";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      alert("Form Submitted Successfully");
      // console.log(values);

      const formData = new FormData();
      formData.append("encrypted_code", values.encrypted_code || "");
      formData.append("name", values.name || "");
      formData.append("gender", values.gender || "");
      formData.append("email_id", values.email_id || "");
      formData.append("phone_number", phoneNumber || "");
      formData.append(
        "whatsapp_phone_number",
        values.whatsapp_phone_number || ""
      );
      formData.append("year_of_birth", values.year_of_birth || "");
      formData.append("age", values.age || "");
      formData.append("education", values.education || "");
      formData.append("experience", values.experience || "");
      formData.append("join_reason", values.join_reason || "");
      formData.append("browser", values.browser || "");
      formData.append("device", values.device || "");
      formData.append(
        "previous_trackbee_experience",
        values.previous_trackbee_experience || ""
      );
      formData.append("state", values.state || "");
      formData.append("district", values.district || "");
      formData.append("city", values.city || "");
      formData.append("marital_status", values.marital_status || "");
      formData.append("survey_methodology", values.survey_methodology || []);
      formData.append("pincode", values.pincode || "");
      formData.append("image", imageName);
      formData.append("proficient_english", values.proficient_english);
      formData.append("proficient_hindi", values.proficient_hindi);
      formData.append("have_whatsapp_number", values.have_whatsapp_number);
      formData.append(
        "self_declaration",
        values.self_declaration === true ? "1" : "0"
      );
      // console.log(formData);

      const phone_number = values.phone_number;
      // console.log(phone_number);

      axios
        .post(API_BASE_URL + "/auth/addMember", formData)
        .then((res) => {
          if (res.data.Status) {
            // console.log(res.data);
            // alert(
            //   "Thank you for applying. Our team will get back to you soon."
            // );
            // window.location.reload();
            navigate("/thankYouMembership");
          } else {
            console.log(res.data.Error);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <HeaderOne />
      <div>
        <section className="bg-half bg-light d-table w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="page-next-level">
                  <h4 className="title">
                    Application Form for Trackbee Certification
                  </h4>
                  <p>
                    To participate in the Trackbee certification training
                    program, fill out and submit the application form. Our team
                    will review the submissions, choose participants for the
                    program, and notify the chosen candidates of their
                    acceptance by sending an invitation
                  </p>
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul
                        className="breadcrumb bg-white rounded shadow mb-0 custom-breadcrumb"
                        style={{ padding: "10px" }}
                      >
                        <li className="breadcrumb-item">
                          <a href="/">TrackBee Community</a>
                        </li>
                        <li className="breadcrumb-item active">Apply</li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-7">
                <div className="custom-form border-0" style={{ border: "0" }}>
                  <div className="card-body">
                    <form
                      className="rounded shadow p-4"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Name
                              <span className="text-danger">
                                *{" "}
                                {errors.name && (
                                  <span className="text-danger small">
                                    {errors.name}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_name" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <User className="fea icon-sm icons" />
                              <input
                                name="name"
                                id="applicant_name"
                                type="text"
                                className="form-control ps-5 validation-require"
                                placeholder="Enter name"
                                maxLength={200}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Gender
                              <span className="text-danger">
                                *{" "}
                                {errors.gender && (
                                  <span className="text-danger small">
                                    {errors.gender}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_gender" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Circle className="fea icon-sm icons" />
                              <select
                                name="gender"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_gender"
                                onChange={handleInputChange}
                              >
                                <option value>Select your gender</option>
                                <option value={1}>Male</option>
                                <option value={2}>Female</option>
                                <option value={4}>Prefer not to say</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Year of birth
                              <span className="text-danger">
                                *{" "}
                                {errors.year_of_birth && (
                                  <span className="text-danger small">
                                    {errors.year_of_birth}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_applicant_birth_year" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Calendar className="fea icon-sm icons" />
                              <select
                                name="year_of_birth"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_birth_year"
                                onChange={handleInputChange}
                              >
                                <option value="Select year">Select year</option>
                                {yearOptions}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Age
                              <span className="text-danger">
                                *{" "}
                                {errors.age && (
                                  <span className="text-danger small">
                                    {errors.age}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_applicant_age" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Clock className="fea icon-sm icons" />
                              <input
                                name="age"
                                id="applicant_age"
                                type="text"
                                className="form-control ps-5 validation-require number"
                                placeholder="Enter Age"
                                maxLength={2}
                                autoComplete="off"
                                onChange={handleInputChange}
                                value={values.age}
                                disabled
                                onKeyPress={(event) => {
                                  // Allow only numeric input (0-9) and prevent other characters
                                  const isValidInput = /[0-9]/.test(event.key);
                                  if (!isValidInput) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mobile Number
                              <span className="text-danger">
                                *{" "}
                                {errors.phone_number && (
                                  <span className="text-danger small">
                                    {errors.phone_number}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_phone_number" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Phone className="fea icon-sm icons" />
                              <input
                                name="phone_number"
                                id="applicant_phone_number"
                                className="form-control ps-5 "
                                placeholder="Enter mobile number"
                                maxLength={10}
                                autoComplete="off"
                                value={phoneNumber}
                                disabled={!!phoneNumber}
                                onChange={handleInputChange}
                                onKeyPress={(event) => {
                                  // Allow only numeric input (0-9) and prevent other characters
                                  const isValidInput = /[0-9]/.test(event.key);
                                  if (!isValidInput) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Is <i className="text-primary"> {phoneNumber}</i>{" "}
                              your WhatsApp number?
                              <span className="text-danger">
                                *{" "}
                                {errors.have_whatsapp_number && (
                                  <span className="text-danger small">
                                    {errors.have_whatsapp_number}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_have_whatsapp_number" />
                            </label>
                            <div className="row">
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="have_whatsapp_number"
                                    id="have_whatsapp_number"
                                    defaultValue={1}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="have_whatsapp_number"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="have_whatsapp_number"
                                    id="have_whatsapp_number"
                                    defaultValue={0}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="have_whatsapp_number"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              WhatsApp Phone Number
                              <span className="text-danger">
                                {errors.whatsapp_phone_number && (
                                  <span className="text-danger small">
                                    {errors.whatsapp_phone_number}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_phone_number" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Phone className="fea icon-sm icons" />
                              <input
                                name="whatsapp_phone_number"
                                id="applicant_phone_number"
                                className="form-control ps-5 "
                                placeholder="Enter alternate mobile number"
                                maxLength={10}
                                autoComplete="off"
                                value={
                                  values.have_whatsapp_number === "1"
                                    ? values.phone_number
                                    : values.whatsapp_phone_number || ""
                                }
                                disabled={values.have_whatsapp_number === "1"}
                                onChange={handleInputChange}
                                onKeyPress={(event) => {
                                  // Allow only numeric input (0-9) and prevent other characters
                                  const isValidInput = /[0-9]/.test(event.key);
                                  if (!isValidInput) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Email{" "}
                              {errors.email_id && (
                                <span className="text-danger small">
                                  {errors.email_id}
                                </span>
                              )}
                              <span className="small font-noraml text-danger error_applicant_email" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Mail className="fea icon-sm icons" />
                              <input
                                name="email_id"
                                id="applicant_email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Enter email"
                                maxLength={200}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State
                              <span className="text-danger">
                                *{" "}
                                {errors.state && (
                                  <span className="text-danger small">
                                    {errors.state}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_state" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Map className="fea icon-sm icons" />
                              <select
                                name="state"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_state"
                                onChange={handleInputChange}
                              >
                                <option value>Select your state</option>
                                <option value="Andaman Nicobar">
                                  Andaman Nicobar
                                </option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Dadra & Nagar Haveli">
                                  Dadra & Nagar Haveli
                                </option>
                                <option value="Daman & Diu">Daman & Diu</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jammu & Kashmir">
                                  Jammu & Kashmir
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Ladakh">Ladakh</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              District{" "}
                              <span className="text-primary">
                                (Please select the State first)
                              </span>
                              <span className="text-danger">
                                *{" "}
                                {errors.district && (
                                  <span className="text-danger small">
                                    {errors.district}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_district" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Navigation className="fea icon-sm icons" />
                              <select
                                name="district"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_district"
                                onChange={handleInputChange}
                                value={values.district}
                                disabled={values.districts.length === 0}
                              >
                                <option value="">Select your District</option>
                                {/* {console.log("District:", values.district)} */}
                                {Array.isArray(values.districts) &&
                                  values.districts.map((district, index) => (
                                    <option key={index} value={district}>
                                      {district}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              City
                              <span className="text-danger">
                                *{" "}
                                {errors.city && (
                                  <span className="text-danger small">
                                    {errors.city}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_city" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MapPin className="fea icon-sm icons" />
                              <input
                                name="city"
                                id="applicant_city"
                                type="text"
                                className="form-control ps-5 validation-require"
                                placeholder="Enter city"
                                maxLength={200}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Pincode
                              <span className="text-danger">
                                *{" "}
                                {errors.pincode && (
                                  <span className="text-danger small">
                                    {errors.pincode}
                                  </span>
                                )}
                                <span className="text-danger error_pincode small" />
                              </span>
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MapPin className="fea icon-sm icons" />
                              <input
                                name="pincode"
                                id="pincode"
                                type="text"
                                className="form-control ps-5 validation-require number"
                                placeholder="Enter pin code"
                                maxLength={6}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Highest education
                              <span className="text-danger">
                                *{" "}
                                {errors.education && (
                                  <span className="text-danger small">
                                    {errors.education}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_applicant_education" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Book className="fea icon-sm icons" />
                              <select
                                name="education"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_education"
                                onChange={handleInputChange}
                              >
                                <option value>Select qualification</option>
                                {/* <option value={1}>Class 8 Pass</option>
                                <option value={2}>SSC</option> */}
                                <option value={3}>HSC</option>
                                <option value={4}>Diploma</option>
                                <option value={5}>Graduate</option>
                                <option value={6}>Postgraduate</option>
                                <option value={7}>Postgraduate Diploma</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Marital Status
                              <span className="text-danger">
                                *{" "}
                                {errors.marital_status && (
                                  <span className="text-danger small">
                                    {errors.marital_status}
                                  </span>
                                )}
                                <span className="text-danger error_married small" />
                              </span>
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Octagon className="fea icon-sm icons" />
                              <select
                                name="marital_status"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="married"
                                onChange={handleInputChange}
                              >
                                <option value>Select marital status</option>
                                <option value={1}>Married</option>
                                <option value={2}>Single</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Have you used the Trackbee mobile application
                              earlier?
                              <span className="text-danger">
                                *{" "}
                                {errors.previous_trackbee_experience && (
                                  <span className="text-danger small">
                                    {errors.previous_trackbee_experience}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_previous_trackbee_experience" />
                            </label>
                            <div className="row">
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="previous_trackbee_experience"
                                    id="previous_trackbee_experience_yes"
                                    defaultValue={1}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="previous_trackbee_experience_yes"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="previous_trackbee_experience"
                                    id="previous_trackbee_experience_no"
                                    defaultValue={0}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="previous_trackbee_experience_no"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Experience
                              <span className="text-danger">
                                *{" "}
                                {errors.experience && (
                                  <span className="text-danger small">
                                    {errors.experience}
                                  </span>
                                )}
                                <span className="text-danger error_married small" />
                              </span>
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Clock className="fea icon-sm icons" />
                              <select
                                name="experience"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                aria-label="Default select example"
                                id="experience_option"
                                onChange={handleInputChange}
                              >
                                <option value>Select experence</option>
                                <option value="0">less than 1 Year</option>
                                <option value="1-5">1 - 5 Years</option>
                                <option value="6-10">6 - 10 Years</option>
                                <option value="11-15">11 - 15 Years</option>
                                <option value="16-99">
                                  More than 15 Years
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Have Experience in{" "}
                              <span className="text-primary">
                                (Multiple Select)
                              </span>
                              <span className="text-danger">
                                *{" "}
                                {errors.survey_methodology && (
                                  <span className="text-danger small">
                                    {errors.survey_methodology}
                                  </span>
                                )}
                                <span className="text-danger small error_survey_methodology" />
                              </span>
                            </label>
                            <div className="row">
                              {[...Array(4)].map((column, columnIndex) => (
                                <div className="col-md-3" key={columnIndex}>
                                  {[
                                    {
                                      id: 1,
                                      label: "CATI",
                                    },
                                    {
                                      id: 2,
                                      label: "CAPI",
                                    },
                                    {
                                      id: 3,
                                      label: "CAWI",
                                    },
                                    {
                                      id: 4,
                                      label: "CLT",
                                    },
                                    {
                                      id: 5,
                                      label: "Mystery Audit/Audit",
                                    },
                                    {
                                      id: 6,
                                      label: "Face2Face Interview",
                                    },
                                    {
                                      id: 7,
                                      label: "FGD",
                                    },
                                    {
                                      id: 8,
                                      label: "Recruitment",
                                    },
                                    {
                                      id: 9,
                                      label: "Moderation",
                                    },
                                    {
                                      id: 10,
                                      label: "Transcription",
                                    },
                                    {
                                      id: 11,
                                      label: "Translation",
                                    },
                                    {
                                      id: 12,
                                      label: "Report Writing",
                                    },
                                    {
                                      id: 13,
                                      label: "Data Analysis",
                                    },
                                  ]
                                    .filter(
                                      (_, index) => index % 4 === columnIndex
                                    )
                                    .map((option) => (
                                      <div
                                        className="form-check form-check-inline text-muted col-12"
                                        key={option.id}
                                      >
                                        <div className="mb-0">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input application_validation"
                                              type="checkbox"
                                              value={option.id}
                                              name="survey_methodology"
                                              checked={values.survey_methodology.includes(
                                                String(option.id)
                                              )}
                                              onChange={handleInputChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`flexCheckDefault${option.id}`}
                                            >
                                              {option.label}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Do you have fluency in English?
                              <span className="text-danger">
                                *{" "}
                                {errors.proficient_english && (
                                  <span className="text-danger small">
                                    {errors.proficient_english}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_fluency_english" />
                            </label>
                            <div className="row">
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="proficient_english"
                                    id="proficient_english"
                                    defaultValue={1}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fluency_english_yes"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="proficient_english"
                                    id="proficient_english"
                                    defaultValue={0}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fluency_english_no"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Do you have fluency in Hindi?
                              <span className="text-danger">
                                *{" "}
                                {errors.proficient_hindi && (
                                  <span className="text-danger small">
                                    {errors.proficient_hindi}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_fluency_hindi" />
                            </label>
                            <div className="row">
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="proficient_hindi"
                                    id="proficient_hindi"
                                    defaultValue={1}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fluency_hindi_yes"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="proficient_hindi"
                                    id="proficient_hindi"
                                    defaultValue={0}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fluency_hindi_no"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Why do you want to join the Trackbee community and
                              get certified?
                              <span className="text-danger">
                                *{" "}
                                {errors.join_reason && (
                                  <span className="text-danger small">
                                    {errors.join_reason}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_join_reason" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MessageCircle className="fea icon-sm icons" />
                              <textarea
                                name="join_reason"
                                id="join_reason"
                                rows={4}
                                className="form-control ps-5 validation-require"
                                placeholder="Comments"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3 mt-4 border-bottom">
                              <h5 className="text-blue">Image Upload</h5>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <img
                                src={selectedImage || avatar2}
                                className="rounded mx-auto d-block w-25"
                                alt="Profile"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center text-muted">
                          <div className="col-md-12 text-center">
                            <div className="mb-3">
                              <label htmlFor="formFile" className="form-label">
                                Upload your profile picture
                                <span className="text-danger">
                                  *{" "}
                                  {errors.image && (
                                    <span className="text-danger small">
                                      {errors.image}
                                    </span>
                                  )}
                                </span>
                                <span className="text-danger error_profile_image small" />
                              </label>
                              <input
                                className="form-control validation-require"
                                type="file"
                                name="image"
                                id="formFile"
                                autoComplete="off"
                                placeholder="Enter File"
                                accept=".png, .jpg, .jpeg"
                                onChange={handleImageChange}
                                // onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3 mt-4 border-bottom">
                              <h5 className="text-blue">
                                Self-Declaration
                                <span className="text-danger small">
                                  *{" "}
                                  {errors.self_declaration && (
                                    <span className="text-danger small">
                                      {errors.self_declaration}
                                    </span>
                                  )}
                                </span>
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <div className="form-check text-muted">
                                <input
                                  className="form-check-input"
                                  name="self_declaration"
                                  type="checkbox"
                                  value="1"
                                  id="self_declaration"
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  I,{" "}
                                  {values.gender === "1"
                                    ? "Shri"
                                    : "" || values.gender === "2"
                                    ? "Smt"
                                    : "" || values.gender === "3"
                                    ? "Shri/Smt"
                                    : ""}
                                  <span
                                    id="applicant_name_text "
                                    className="text-primary"
                                  >
                                    {" " + values.name}
                                  </span>
                                  , hereby declare that the information given
                                  above and in the enclosed documents is true to
                                  the best of my knowledge and belief and
                                  nothing has been concealed therein.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-4 d-flex justify-content-center">
                          <button
                            id="membership_apply"
                            className="submitBnt btn btn-primary"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FooterOne />
    </div>
  );
};

export default ProfileForm;
