import React, { useEffect, useState } from "react";
import logodark from "../assets/front_images/logo-dark.png";
import logolight from "../assets/front_images/logo-light.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ArrowUp, Facebook, Linkedin, Twitter } from "react-feather";
import axios from "axios";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import defaultImage from "../assets/images/v.jpg";
import badge from "../assets/images/ribbon-badge_9319071.png";
import "../styles/Style.css";
import { API_BASE_URL, REACT_APP_SECRET_KEY } from "./env";
import Header from "./Header";
const itemsPerPage = 51;
// const API_BASE_URL = "http://localhost:3000";

const PublicDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [values, setValues] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [phoneno, setPhoneno] = useState("");
  const [state, setState] = useState("0");
  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");
  const [loading, setLoading] = useState(true);

  function toggleMenu() {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  }

  // console.log(values);

  const stateMapping = {
    1: "Andaman Nicobar",
    2: "Andhra Pradesh",
    3: "Arunachal Pradesh",
    4: "Assam",
    5: "Bihar",
    6: "Chandigarh",
    7: "Chhattisgarh",
    8: "Dadra & Nagar Haveli and Daman and Diu",
    9: "Delhi",
    10: "Goa",
    11: "Gujarat",
    12: "Haryana",
    13: "Himachal Pradesh",
    14: "Jammu & Kashmir",
    15: "Jharkhand",
    16: "Karnataka",
    17: "Kerala",
    18: "Ladakh",
    19: "Lakshadweep",
    20: "Madhya Pradesh",
    21: "Maharashtra",
    22: "Manipur",
    23: "Meghalaya",
    24: "Mizoram",
    25: "Nagaland",
    26: "Odisha",
    27: "Puducherry",
    28: "Punjab",
    29: "Rajasthan",
    30: "Sikkim",
    31: "Tamil Nadu",
    32: "Telangana",
    33: "Tripura",
    34: "Uttar Pradesh",
    35: "Uttarakhand",
    36: "West Bengal",
  };

  const genderMapping = {
    1: "Male",
    2: "Female",
    4: "Prefer not to say",
  };

  const EducationMapping = {
    1: "Class 8 Pass",
    2: "SSC",
    3: "HSC",
    4: "Diploma",
    5: "Graduate",
    6: "Postgraduate",
  };
  const MaritalStatusMapping = {
    1: "Married",
    2: "Single",
  };
  const SurveyMethodologyMapping = {
    1: "CATI (Computer Assisted Telephone Interviewing)",
    2: "CAPI (Computer-Assisted Personal Interviews)",
    3: "CAWI (Computer-assisted web interviewing)",
    4: "CLT (Central Location Test)",
    5: "Others (Please Specify)",
  };

  const ProjectsDoneMapping = {
    1: "Door-to -door surveys",
    2: "Household surveys",
    3: "Social Surveys (Baseline/Endline/Midline)",
    4: "Impact Assessment Surveys",
    5: "In -Depth Interview",
    6: "Mystery Audit",
    7: "Others (Please Specify)",
  };

  const DurationMapping = {
    1: "Less than 2 weeks",
    2: "3 - 6 Weeks",
    3: "7 - 10 Weeks",
    4: "More than 10 Weeks",
  };

  const ModeOfCollectionMapping = {
    1: "Online Using Mobile App",
    2: "Web Survey Using Mobile Device",
    3: "Pen-n-Paper",
  };

  const SampleNoMapping = {
    1: "Less than 100",
    2: "100-300",
    3: "301-500",
    4: "More than 500",
  };

  const [gender, setGender] = useState("0");

  const [surveyMethodology, setSurveyMethodology] = useState("0");
  const [age, setAge] = useState("0");
  const [experience, setExperience] = useState("0");
  // const [filteredValues, setFilteredValues] = useState([]);
  const [enrolledMember, setEnrolledMember] = useState([]);
  const [topprojects, setTopprojects] = useState([]);

  const getEnrolledMemberTopProjects = async (id) => {
    try {
      const res = await axios.get(
        API_BASE_URL + "/auth/getEnrolledMemberTopProjects/" + id
      );
      // console.log(res.data);
      setTopprojects(res.data.Result);
    } catch (err) {
      console.log(err);
    }
  };

  function capitalizeFirstLetter(name) {
    if (name) {
      return name
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    } else {
      return "";
    }
  }

  // useEffect(() => {
  //   // Fetch initial data or load all data
  //   fetchData();
  // }, []); // Add dependencies as needed

  const fetchData = () => {
    setLoading(true);
    axios
      .get(API_BASE_URL + "/auth/getEnrolledPublicDashboard")
      .then((res) => {
        if (res.data.Status) {
          setValues(res.data.Result);
          setLoading(false);
          setGender("0");
          setState("0");
          setSurveyMethodology("0");
          setAge("0");
          setExperience("0");
          setCurrentPage(1);
          navigate(location.pathname, { state: null });
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // If location.state is available, set values from it
    // console.log(location);
    setLoading(true);
    if (location && location.state && location.state.length > 0) {
      setValues(location.state);
      setState(location.state[0].state);
      // setDistrict(location.state[0].district);
      setLoading(false);
    } else {
      // If location.state is not available, fetch default data
      fetchData();
    }
  }, []);

  const handleFilter = () => {
    setLoading(true);
    // Make a request to the server with filter values
    const [ageStart, ageEnd] = age.split("-").map(Number);
    const [experienceStart, experienceEnd] = experience.split("-").map(Number);
    // console.log(experienceStart);
    // console.log(experienceEnd);
    axios
      .post(API_BASE_URL + "/auth/filterEnrolledPublicDashboard", {
        gender,
        state,
        district,
        surveyMethodology,
        ageStart,
        ageEnd,
        experienceStart,
        experienceEnd,
        experience,
      })
      .then((res) => {
        if (res.data.Status) {
          // console.log(res.data.Result);
          setValues(res.data.Result);
          setCurrentPage(1);
          setLoading(false);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = values.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalResults = values.length;
  const currentResultsStart = indexOfFirstItem + 1;
  const currentResultsEnd = Math.min(indexOfLastItem, totalResults);

  const surveyMethodologyString = enrolledMember.survey_methodology || "";
  const surveyMethodologyArray = surveyMethodologyString.split(",").map(Number);
  const formattedSurveyMethodology = surveyMethodologyArray.map(
    (value) => SurveyMethodologyMapping[value]
  );
  const projectsDoneString = enrolledMember.survey_methodology || "";
  const projectsDoneArray = projectsDoneString.split(",").map(Number);
  const formattedProjectsDone = projectsDoneArray.map(
    (value) => ProjectsDoneMapping[value]
  );
  // console.log(topprojects);
  //   console.log(surveyMethodologyArray);
  const individualArrays = topprojects.map((project) =>
    project.data_collection_mode.split(",")
  );

  // Collect all individual arrays into a single array
  const allDataCollectionModes = individualArrays.reduce(
    (accumulator, currentArray) => {
      return accumulator.concat([currentArray]);
    },
    []
  );

  useEffect(() => {
    // Fetch districts if state is already selected
    if (state) {
      axios
        .get(API_BASE_URL + `/auth/get-district/${state}`)
        .then((res) => {
          const districts = res.data.Result;
          setDistricts(districts);

          // Preselect previous district if it exists in the fetched districts
          // if (district && districts.includes(district)) {
          //   setDistrict(district);
          // }
        })
        .catch((error) => {
          console.error("Error fetching district information:", error);
        });
    }
  }, [state, district]);

  // useEffect(() => {
  //   // Simulating loading for 2 seconds, you can replace this with actual loading logic
  //   const timer = setTimeout(() => {
  //     setLoading(false); // After 2 seconds, set loading to false
  //   }, 2000);

  //   return () => clearTimeout(timer); // Clear timer on component unmount
  // }, []);

  return (
    <>
      <div className={`wrapper ${loading ? "loading" : ""}`}>
        {/* Spinner component */}
        {loading && (
          <div className="loading-overlay-publicDashboard">
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              className="loading-spinner-publicDashboard"
            />
            Loading...
          </div>
        )}
        {/* Header */}
        {/* <header id="topnav" className="defaultscroll sticky border-bottom">
          <div className="container">
            <Link className="logo" to="/">
              <img
                src={logodark}
                style={{ height: "70px" }}
                className="logo-light-mode"
                alt="logo-dark"
              />
              <img
                src={logolight}
                style={{ height: "70px" }}
                className="logo-dark-mode"
                alt="logo-light"
              />
            </Link>
            <div className="menu-extras">
              <div className="menu-item">
                <button
                  className="navbar-toggle"
                  id="isToggle"
                  onClick={() => toggleMenu()}
                >
                  <div className="lines">
                    <span />
                    <span />
                    <span />
                  </div>
                </button>
              </div>
            </div>
            <div id="navigation" className="align-items-end">
              <ul className="navigation-menu">
                <li>
                  <Link to="/mapView" className="sub-menu-item">
                    Map view
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header> */}
        <Header />

        {/* Main Content */}
        <main>
          <section className="section pb-0">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3">
                {" "}
                <div className="col mt-4 pt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="state_option"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="0">Select your state</option>
                    <option value="Andaman Nicobar">Andaman Nicobar</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra & Nagar Haveli">
                      Dadra & Nagar Haveli
                    </option>
                    <option value="Daman & Diu">Daman & Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu & Kashmir">Jammu & Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
                {/* <div className="col mt-4 pt-2">
                  <select
                    name="district"
                    className="form-select"
                    aria-label="Default select example"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    // disabled={!values.districts || values.districts.length === 0}
                  >
                    <option value="">Select your district</option>
                    {console.log("District:", district)}
                    {Array.isArray(districts) &&
                      districts.map((district, index) => (
                        <option key={index} value={district}>
                          {district}
                        </option>
                      ))}
                  </select>
                </div> */}
                <div className="col mt-4 pt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="age_option"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  >
                    <option value="0">Select age</option>
                    <option value="18-25">18 - 25</option>
                    <option value="26-30">26 - 30</option>
                    <option value="31-35">31 - 35</option>
                    <option value="36-40">36 - 40 </option>
                    <option value="41-99">More than 40 year</option>
                  </select>
                </div>
                <div className="col mt-4 pt-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="gender_option"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="0">Select gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Others</option>
                  </select>
                </div>
                <div className="col mt-4 pt-2 text-md-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm me-3"
                    id="filter_enumerators"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    id="reset_enumerators"
                    onClick={fetchData}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="section pt-1" style={{ marginBottom: "0px" }}>
            <div className="container">
              {values.length > 0 ? (
                <>
                  <div className="row">
                    {currentItems.map((element, index) => (
                      <div key={index} className="col-md-4 mt-4 pt-2">
                        <div title={element.name}>
                          <div className="d-flex align-items-center position-relative">
                            <img
                              src={
                                !!element.image
                                  ? API_BASE_URL + "/images/" + element.image
                                  : defaultImage
                              }
                              className="avatar avatar-medium rounded-circle img-thumbnail"
                              alt=""
                              style={{ width: "100px", height: "100px" }}
                            />
                            <div className="content ms-2">
                              <div
                                className="d-flex align-items-center mb-0"
                                style={{ fontSize: "20px" }}
                              >
                                <span>
                                  {capitalizeFirstLetter(element.name)}
                                </span>
                                {element.certified === "1" && (
                                  <div className="certifiedBadge ms-1">
                                    <img
                                      src={badge}
                                      alt="Certified Badge"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <small className="position text-muted">
                                <span className="text-dark">
                                  Age:{" "}
                                  {new Date().getFullYear() -
                                    element.year_of_birth}
                                </span>
                              </small>
                              <br />
                              <small className="position text-muted">
                                <span className="text-dark">
                                  Gender: {genderMapping[element.gender]}
                                </span>
                              </small>
                              <br />
                              <small className="position text-muted">
                                <span className="text-dark">
                                  State: {element.state}{" "}
                                  {!!element.pincode
                                    ? "- " + element.pincode
                                    : " "}
                                </span>
                              </small>
                              <br />
                              <small className="position text-muted">
                                <span className="text-dark me-2">
                                  Contact no: {"+91 "}
                                  {"xxxxxxxx" +
                                    element.phone_number.toString().slice(-2)}
                                </span>
                              </small>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Pagination */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: "100px", fontSize: "18px" }}
                  >
                    <div>
                      <p>
                        Showing {currentResultsStart} – {currentResultsEnd} of{" "}
                        {totalResults} results
                      </p>
                    </div>

                    {/* Pagination */}
                    <div className="pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="prev-btn"
                      >
                        <BsChevronLeft />
                      </button>
                      {Array.from(
                        { length: Math.ceil(totalResults / itemsPerPage) },
                        (_, index) => {
                          const pageNumber = index + 1;
                          // Only render adjacent page numbers based on current page
                          if (
                            pageNumber === currentPage ||
                            pageNumber === currentPage - 1 ||
                            pageNumber === currentPage + 1
                          ) {
                            return (
                              <button
                                key={index + 1}
                                onClick={() => handlePageChange(pageNumber)}
                                className={
                                  currentPage === pageNumber ? "active" : ""
                                }
                              >
                                {pageNumber}
                              </button>
                            );
                          }
                          // If not adjacent pages, render nothing
                          return null;
                        }
                      )}
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage === Math.ceil(totalResults / itemsPerPage)
                        }
                        className="next-btn"
                      >
                        <BsChevronRight />
                      </button>
                    </div>

                    {/* Results Info */}
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-12 mt-5 mx-2 pt-2">
                    <h5
                      className="text-danger text-center"
                      style={{ marginBottom: "8%", marginTop: "8%" }}
                    >
                      No enumerator found
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </section>
          {/* {modal && (
            <Modal
              isOpen={modal}
              toggle={() => setModal(!modal)}
              backdrop="static"
              modalClassName="custom-modal"
              style={{
                zIndex: 1050,
                top: "50%",
                left: "18%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ModalHeader toggle={() => setModal(!modal)}>
                Contact Number
              </ModalHeader>
              <ModalBody>
                <h5 className="text-blue">{"+91 " + phoneno}</h5>
              </ModalBody>
            </Modal>
          )}

          {modalDetails && (
            <Modal
              isOpen={modalDetails}
              toggle={() => setModalDetails(!modalDetails)}
              backdrop="static"
              modalClassName="custom-modal"
              style={{ zIndex: 1050 }}
            >
              <ModalHeader toggle={() => setModalDetails(!modalDetails)}>
                Member Details
              </ModalHeader>
              <ModalBody>
                <div className="row p-2 border">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="text-blue">
                          <b>Name:</b> {enrolledMember.name}
                        </h5>
                        <p>
                          <b>Year of birth:</b> {enrolledMember.year_of_birth}
                          <br />
                          <b>Gender:</b> {genderMapping[enrolledMember.gender]}
                          <br />
                          <br />
                          <b>Phone Number: </b>+91 {enrolledMember.phone_no}
                          <br />
                          <b>Email:</b> {enrolledMember.email_id}
                          <br />
                          <b>Address:</b> {enrolledMember.state} -{" "}
                          {enrolledMember.pincode}
                          <br />
                          <br />
                          <b>Education:</b>{" "}
                          {EducationMapping[enrolledMember.education]}
                          <br />
                          <b>Marital Status:</b>{" "}
                          {MaritalStatusMapping[enrolledMember.marital_status]}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 text-center mb-3">
                    <img
                      src={
                        "http://localhost:3000/images/" + enrolledMember.image
                      }
                      width={150}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12">
                    <p>
                      <b>
                        Have you used the Trackbee mobile application earlier?
                      </b>{" "}
                      {enrolledMember.mobile_app === "1" ? "Yes" : "No"}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 mt-4 border-bottom">
                      <h5 className="text-blue">Experience</h5>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <p>
                      <b>
                        Total Experience in field data collection (in Years):
                      </b>{" "}
                      {enrolledMember.experience}
                      <br />
                      {enrolledMember.experience > 0 && (
                        <>
                          <b>Survey methodology:</b>{" "}
                          {formattedSurveyMethodology.join(" | ")}
                          <br />
                          <b>Projects type:</b>{" "}
                          {formattedProjectsDone.join(" | ")}
                        </>
                      )}
                    </p>
                  </div>
                  {enrolledMember.experience > 0 && (
                    <>
                      <div className="col-md-12">
                        <div className="mb-3 mt-4 border-bottom">
                          <h5 className="text-blue">
                            Details of TOP 3 project
                          </h5>
                        </div>
                      </div>
                      {topprojects.length > 0 &&
                        topprojects.map((element, index) => (
                          <div
                            className={`col-md-12 ${
                              index === 0 ? "" : "border-top pt-3"
                            }`}
                            key={index}
                          >
                            <p>
                              <b>Project Name:</b> {element.project_name}
                              <br />
                              <b>Survey methodology:</b>{" "}
                              {
                                SurveyMethodologyMapping[
                                  element.survey_methodology
                                ]
                              }
                              <br />
                              <b>Project Type:</b>{" "}
                              {ProjectsDoneMapping[element.projects_done]}
                              <br />
                              <b>Duration (in Week):</b>{" "}
                              {DurationMapping[element.duration]}
                              <br />
                              <b>Mode of data collection:</b>{" "}
                              {allDataCollectionModes[index]
                                .map((value) => ModeOfCollectionMapping[value])
                                .join(" | ")}
                             
                              <br />
                              <b>Number of samples collected:</b>{" "}
                              {SampleNoMapping[element.sample_no]}
                              <br />
                              <b>State:</b> {stateMapping[element.state]}
                              <br />
                            </p>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </ModalBody>
            </Modal>
          )} */}
        </main>

        {/* Footer */}
        <div className="footer mt-4 ">
          <div className="footer-py-15 footer-bar">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="text-sm-start">
                    <p className="mb-0">
                      © {currentYear + " "}TrackBee Community. All rights
                      reserved.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 mt-2 mt-sm-0 pt-2 pt-sm-0 text-md-end">
                  <ul className="list-unstyled social-icon social mb-0">
                    {/* <li className="list-inline-item">
                      <Link to="/publicDashboard" className="rounded">
                        <Facebook className="fea icon-sm fea-social" />
                      </Link>
                    </li> */}
                    <li className="list-inline-item">
                      <Link
                        to="https://twitter.com/TrackbeeSystem"
                        target="_blank"
                        className="rounded"
                      >
                        <Twitter className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="https://www.linkedin.com/company/trackbee-data-solutions/"
                        target="_blank"
                        className="rounded"
                      >
                        <Linkedin className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#top"
            id="back-to-top"
            className="btn btn-icon btn-light back-to-top"
          >
            <ArrowUp className="icons"></ArrowUp>
          </a>
        </div>
      </div>
    </>
  );
};

export default PublicDashboard;
