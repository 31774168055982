import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import banner from "../assets/front_images/banner.jpg";
import homebg from "../assets/front_images/digital/home-bg.png";
import events from "../assets/front_images/illustrator/events.svg";
import howitwork from "../assets/front_images/illustrator/how-it-work.svg";
import contactus from "../assets/front_images/illustrator/contact-us.svg";
import YouTube from "react-youtube";
import { HelpCircle, ArrowRight, Video } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../styles/VideoOverlay.css"; // Add the path to your CSS file
import "../styles/Style.css";


const VideoOverlay = ({ videoId, closeOverlay }) => {
  const videoOpts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="video-overlay">
      <div className="video-container">
        <YouTube
          videoId={videoId}
          opts={videoOpts}
          onReady={(event) => event.target.playVideo()}
        />
        <button className="close-btn" onClick={closeOverlay}>
          <span role="img" aria-label="Close">
            &#10060;
          </span>
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showVideo, setShowVideo] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Retrieve hash from route state
    const { state } = location;
    if (state && state.hash) {
      const element = document.getElementById(state.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 12000); // 12 seconds

    return () => clearTimeout(popupTimer);
  }, []);

  const openVideo = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleApplyNow = () => {
    // Add logic for the "Apply Now" button
    // For example, you can navigate to the membership form page
    // You may use react-router-dom's history or Link component
    navigate("/profileForm");
  };

  const handleLater = () => {
    // Add logic for the "Later" button
    // For example, you can simply close the popup
    closePopup();
  };


  return (
    <div>
      {showPopup && (
        <div className="popup-container">
          <div className="popup">
            <p>Apply for Trackbee Community Membership now !!</p>
            <button onClick={handleApplyNow}>Apply Now</button>
            <br />{" "}
            {/* Add a line break to position "Later" below "Apply Now" */}
            <a href="#later" onClick={handleLater} className="blue-link">
              Do It Later
            </a>
          </div>
        </div>
      )}
      <Header />
      <section
        className="bg-half-260 d-table w-100"
        id="home"
        style={{ background: `url(${homebg})` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="title-heading mt-4">
                <div
                  className="alert alert-light alert-pills shadow "
                  role="alert"
                >
                  <span className="content text-blue"> Trackbee Community</span>
                </div>
                <h1 className="heading mb-3">
                  <span className="text-blue">Empowering</span> Enumerators to
                  Excel
                </h1>
                <p className="para-desc text-muted">
                  This is a first-of-its-kind initiative with an aim to provide
                  enumerators with a platform for growth through training,
                  profile building, and directory listing.
                </p>
                <div className="mt-4 pt-2">
                  <Link
                    to="/profileForm"
                    className="btn btn-pills btn-primary m-1"
                  >
                    Apply Now
                  </Link>
                  {/* Show YouTube video when the button is clicked */}
                  <button
                    onClick={openVideo}
                    className="btn btn-icon btn-pills btn-primary m-1 lightbox"
                  >
                    <Video className="icons" />
                  </button>
                  <span className="fw-bold text-uppercase small align-middle ms-1">
                    Watch Now
                  </span>
                  {showVideo && (
                    <VideoOverlay
                      videoId="y2XAVwtA-6Q"
                      closeOverlay={closeVideo}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <img src={banner} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section overflow-hidden" id="community">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card border-0 text-center features feature-clean rounded p-3">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-chat-bubble-user d-block rounded h3 mb-0" />
                </div>
                <div className="card-body p-0 mt-4">
                  <div className="title h5 text-dark">
                    Importance of Enumerators
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    Enumerators play a vital role in ensuring accurate and
                    unbiased data collection. They act as a bridge between
                    researchers and the target population, representing the
                    research team in the field.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-4 pt-sm-0">
              <div className="card border-0 text-center features feature-clean rounded p-3">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-sitemap d-block rounded h3 mb-0" />
                </div>
                <div className="card-body p-0 mt-4">
                  <div className="title h5 text-dark">Trackbee Community</div>
                  <p className="text-muted mt-3 mb-0">
                    It is a network of enumerators that provides free training
                    and certification for its members. It also offers a public
                    directory for enumerators to showcase their experience and
                    connect with each other.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-4 pt-lg-0">
              <div className="card border-0 text-center features feature-clean rounded p-3">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-postcard d-block rounded h3 mb-0" />
                </div>
                <div className="card-body p-0 mt-4">
                  <div className="title h5 text-dark">
                    Trackbee Certification
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    A program that provides training and recognition for
                    enumerators, it demonstrates their knowledge and expertise in
                    the field. It aims to provide a way for them to enhance
                    their professional credibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section overflow-hidden" id="event">
        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-12 order-2 order-md-1">
              <div className="section-title">
                <div
                  className="alert alert-light alert-pills shadow"
                  role="alert"
                >
                  <span className="content text-blue">Event</span>
                </div>
                <h4 className="title mb-4">Upcoming Event</h4>
                {/* <p className="text-muted mb-2">
                  Enumerator Training &amp; Certification Programme:
                </p> */}
                {/* <ul className="list-unstyled text-muted ms-2">
                  <li className="text-muted mb-1 ps-4 li-background">
                    <strong>Location:</strong> Kolkata
                  </li>
                  <li className="text-muted mb-1 ps-4 li-background">
                    <strong>Date:</strong> 31<sup>st</sup> January, 2023
                  </li>
                  <li className="text-muted mb-1 ps-4 li-background">
                    <strong>Seat capacity:</strong> 15
                  </li>
                </ul> */}

                {/* <a
                  href="/profileForm"
                  className="text-primary btn btn-outline-primary px-3 py-1 rounded ms-3"
                >
                  Apply Now <ArrowRight className="fea icon-sm" />
                </a>
                <p className="text-muted mt-4 mb-2">
                  Enumerator Training &amp; Certification Programme:
                </p>
                <ul className="list-unstyled text-muted ms-2">
                  <li className="text-muted mb-1 ps-4 li-background">
                    <strong>Location:</strong> New Delhi
                  </li>
                  <li className="text-muted mb-1 ps-4 li-background">
                    <strong>Date:</strong> To be announced
                  </li>
                  <li className="text-muted mb-1 ps-4 li-background">
                    <strong>Seat capacity:</strong> 15
                  </li>
                </ul>
                <a
                  href="/profileForm"
                  className="text-primary btn btn-outline-primary px-3 py-1 rounded ms-3"
                >
                  Apply Now <ArrowRight className="fea icon-sm" />
                </a> */}
                <p className="text-muted mb-1">To be announced soon</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <img src={events} alt="events" />
            </div>
          </div>
        </div>
      </section>
      <section className="section overflow-hidden">
        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={howitwork} height="800px" className="img-fluid" alt="how-it-work" />
            </div>
            {/*end col*/}
            <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title">
                <div
                  className="alert alert-light alert-pills shadow"
                  role="alert"
                >
                  <span className="content text-blue">
                    Trackbee training, certification and community registration
                  </span>
                </div>
                <h4 className="title mb-4">How does it work?</h4>
                <ul className="list-unstyled text-muted">
                  <li className="text-muted mb-3 ps-4 li-background">
                    <strong>Joining the Community: </strong> Field enumerators,
                    field investigators, mystery auditors, and field supervisors
                    interested in survey data collection, field audit, or
                    mystery shopping-related projects can apply to join the
                    community. After submitting your application, our team will
                    contact you within two business days to verify your
                    identity. Once your identity is verified, you will become a
                    member of the community.
                  </li>
                  <li className="text-muted mb-3 ps-4 li-background">
                    <strong>Training:</strong> TrackBee arranges training
                    sessions for its community members. Sessions led by various
                    industry experts will be organized periodically to benefit
                    community members.
                  </li>
          
                  <li className="text-muted mb-3 ps-4 li-background">
                    <strong>Participation in Projects:</strong> TrackBee will
                    post field-related, audit-related, or mystery
                    shopping-related jobs on the community portal. These jobs
                    may originate from TrackBee or its partners. Members can
                    directly apply for these jobs.
                  </li>
                  <li className="text-muted mb-3 ps-4 li-background">
                    <strong> Recognition and Growth: </strong> Active members
                    and top performers will be highlighted on the portal. In the
                    coming days, we will introduce a reward and recognition
                    program to attract new talents and encourage members to
                    excel.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="faq">
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <h4 className="title mb-4">Frequently Asked Questions</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  Not sure about something in the Trackbee community? Check out
                  the FAQ section for quick answers
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="accordion mt-4 pt-2" id="buyingquestion">
              <div className="accordion-item rounded" style={{ border: "0" }}>
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button border-0 bg-transparent  collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          What is the Trackbee Community?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    The Trackbee Community encompasses a network of
                    professionals such as field enumerators, investigators,
                    mystery auditors, and supervisors who are integral to the
                    Market Research Industry. This community is orchestrated by
                    Trackbee Data Solutions Pvt Ltd, connecting industry
                    practitioners directly.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          How do I apply for the Trackbee Community
                          Certification Program?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    To participate in the training and certification program,
                    you need to apply for the same through our website only.
                    After reviewing your application, our team will send you an
                    invitation to participate in the training and certification
                    program. Please note we have limited seats for each batch,
                    and spot registration is not available right now.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          What does the Trackbee Community Certification Program
                          cover?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    The Trackbee Community Certification Program covers topics
                    such as the roles of enumerators, data collection
                    techniques, how to avoid data rejection, hands-on experience
                    with mobile app-based data collection, and ethical
                    consideration. It is designed to provide individuals with
                    the knowledge and skills they need to work as an enumerator.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          Who qualifies as a Certified Community Member?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    A Certified Community Member is an individual who has
                    successfully completed a training session facilitated by
                    Trackbee. Such members are distinguished by a unique badge
                    on their online profile, enhancing their visibility and
                    preference in the selection process for job assignments.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          What are the costs associated with joining and
                          obtaining certification within the Trackbee Community?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    Joining the Trackbee Community and achieving certification
                    is entirely complimentary. We impose no fees for
                    registration or for participating in our training programs.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          How can I remove my profile from the Trackbee
                          Community?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    We regret to see you consider leaving. Our community offers
                    a valuable program at no cost, aimed at empowering
                    enumerators. Should you decide to proceed, please contact us
                    via email at community@Trackbee.com for profile deletion.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          How does one become a member of the Trackbee
                          Community?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    To join, navigate to community.Trackbee.com and apply for
                    membership. The process involves phone number verification
                    and the submission of essential information such as your
                    name, address, and experience. Our team will conduct a
                    verification via video call, during which you may be asked
                    to present a government-issued ID for identity
                    verification—no need to upload or email your ID card. Upon
                    successful identity confirmation, you will be welcomed as a
                    Trackbee Community Member.eck the Trackbee Community website
                    for the schedule.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          Steps to becoming a Certified Community Member in
                          Trackbee?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    Membership precedes certification. As a community member,
                    attend our complimentary training session to advance to a
                    Certified Community Member. For information on upcoming
                    sessions, please consult our website.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          Benefits of becoming a Trackbee Community or Certified
                          Community Member?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    <ul className="list-unstyled text-muted">
                      <li className="mb-0">
                        <span className="text-muted h5 me-2">
                          <i className="uil uil-arrow-right align-middle" />
                        </span>
                        Complimentary listing in our public directory.
                      </li>
                      <li className="mb-0">
                        <span className="text-muted h5 me-2">
                          <i className="uil uil-arrow-right align-middle" />
                        </span>
                        Free training sessions for knowledge enhancement.
                      </li>
                      <li className="mb-0">
                        <span className="text-muted h5 me-2">
                          <i className="uil uil-arrow-right align-middle" />
                        </span>
                        Access to apply for projects or assignments in your area
                        without any charges.
                      </li>
                      <li className="mb-0">
                        <span className="text-muted h5 me-2">
                          <i className="uil uil-arrow-right align-middle" />
                        </span>
                        Elevated recognition within the industry.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingTen">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          The significance of Trackbee Community and Certified
                          Membership in the Market Research Industry?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    This innovative initiative offers a platform where partner
                    companies can share their requirements, which are then made
                    accessible to our members for application. This
                    collaboration is pioneering in the industry.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingEleven">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          How to partner with the Trackbee Community?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    Interested in partnering? Please send your inquiry via email
                    to mohit.g@Trackbee.com detailing your requirements. Upon
                    approval, your needs will be posted on our community portal.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingTwelve">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwelve"
                    aria-expanded="false"
                    aria-controls="collapseTwelve"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          How does the Trackbee Community benefit partners?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTwelve"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingTwelve"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    Our community facilitates direct connections between
                    partners and enumerators, including those in remote areas.
                    This efficiency helps in reducing overall operational costs.
                  </div>
                </div>
              </div>
              <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingThirteen">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirteen"
                    aria-expanded="false"
                    aria-controls="collapseThirteen"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">
                          Who can become a member of the Trackbee community?
                        </h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseThirteen"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingThirteen"
                  data-bs-parent="#buyingquestion"
                >
                  <div className="accordion-body text-muted bg-white">
                    Membership in the Trackbee community is open to individuals
                    residing in India, aged between 18 to 50 years old, with a
                    minimum educational qualification of having passed the 12th
                    grade.
                  </div>
                </div>
              </div>
              {/* <div
                className="accordion-item rounded mt-2"
                style={{ border: "0" }}
              >
                <h2 className="accordion-header" id="headingFourteen">
                  <button
                    className="accordion-button border-0 bg-transparent collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFourteen"
                    aria-expanded="false"
                    aria-controls="collapseFourteen"
                  >
                    <div className="d-flex">
                      <HelpCircle className="fea icon-ex-md text-primary me-2 mt-1" />
                      <div className="flex-1">
                        <h5 className="mt-0">How does it works?</h5>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFourteen"
                  className="accordion-collapse border-0 collapse"
                  aria-labelledby="headingFourteen"
                  data-bs-parent="#buyingquestion"
                >
                  <div
                    id="collapseFourteen"
                    className="accordion-collapse border-0 collapse"
                    aria-labelledby="headingFourteen"
                    data-bs-parent="#buyingquestion"
                  >
                    <div className="accordion-body text-muted bg-white">
                      <ul className="list-unstyled text-muted">
                        <li className="mb-0">
                          <span className="text-muted h5 me-2">
                            <i className="uil uil-arrow-right align-middle" />
                          </span>
                          <b>Joining the Community:</b> Field enumerators, field
                          investigators, mystery auditors, and field supervisors
                          interested in survey data collection, field audit, or
                          mystery shopping-related projects can apply to join
                          the community. After submitting your application, our
                          team will contact you within two business days to
                          verify your identity. Once your identity is verified,
                          you will become a member of the community.
                        </li>
                        <li className="mb-0">
                          <span className="text-muted h5 me-2">
                            <i className="uil uil-arrow-right align-middle" />
                          </span>
                          <b>Training:</b> Trackbee arranges training sessions for its
                          community members. Sessions led by various industry
                          experts will be organized periodically to benefit
                          community members.
                        </li>
                        <li className="mb-0">
                          <span className="text-muted h5 me-2">
                            <i className="uil uil-arrow-right align-middle" />
                          </span>
                          <b>Participation in Projects:</b> Trackbee will post
                          field-related, audit-related, or mystery
                          shopping-related jobs on the community portal. These
                          jobs may originate from Trackbee or its partners.
                          Members can directly apply for these jobs.
                        </li>
                        <li className="mb-0">
                          <span className="text-muted h5 me-2">
                            <i className="uil uil-arrow-right align-middle" />
                          </span>
                          <b>Recognition and Growth:</b> Active members and top
                          performers will be highlighted on the portal. In the
                          coming days, we will introduce a reward and
                          recognition program to attract new talents and
                          encourage members to excel.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor" />
          </svg>
        </div>
      </div>
      {/* End */}
      <section className="section border-bottom bg-light" id="contact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5 col-12 order-1 order-md-2">
              <img
                src={contactus}
                className="img-fluid mx-auto d-block"
                alt="contact-us"
              />
            </div>
            {/*end col*/}
            <div className="col-lg-7 col-md-7 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-4">Have Question? Get in touch!</h4>
                <p className="text-muted para-desc mb-0">
                  If you can't find the information you're looking for on this
                  page, please don't hesitate to contact us. You can reach us
                  by:
                </p>
                <ul className="list-unstyled text-muted mt-3">
                  {/* <li className="mb-0">
                    <span className="text-muted h5 me-2">
                      <i className="uil uil-forwaded-call align-middle" />
                    </span>
                    Phone:{" "}
                    <a
                      href="tel:+919433447272"
                      rel="noreferrer"
                      target="_blank"
                      className="text-primary"
                    >
                      +91 943 344 7272
                    </a>
                  </li>
                  <li className="mb-0">
                    <span className="text-muted h5 me-2">
                      <i className="uil uil-whatsapp align-middle" />
                    </span>
                    WhatsApp:{" "}
                    <a
                      href="https://wa.me/919433447272"
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary"
                    >
                      +91 943 344 7272
                    </a>
                  </li> */}
                  <li className="mb-0">
                    <span className="text-muted h5 me-2">
                      <i className="uil uil-envelope-alt align-middle" />
                    </span>
                    Email:{" "}
                    <a
                      href="mailto:community@Trackbee.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary"
                    >
                      community@Trackbee.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      <Footer />
    </div>
  );
};

export default Home;
