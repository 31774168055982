import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home.js";
// import MembershipForm from "./components/MembershipForm.js";
// import MembershipFormTest from "./components/MembershipFormTest.js";
// import EnrollmentForm from "./components/EnrollmentForm.js";
// import EnrollmentFormTest from "./components/EnrollmentFormTest.js";
import Login from "./components/Login.js";
import ApplicantDashboard from "./components/ApplicantDashboard.js";
import VerifiedMember from "./components/VerifiedMember.js";
import CertifiedMember from "./components/CertifiedMember.js";
import EditApplicantDashboard from "./components/EditApplicantDashboard.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import TermsCondition from "./components/TermsCondition.js";
// import Enrolled from "./components/Enrolled.js";
// import EditEnrollmentForm from "./components/EditEnrollmentForm.js";
import ThankYouMembership from "./components/ThankYouMembership.js";
import ThankYouMessage from "./components/ThankYouMessage.js";
import PublicDashboard from "./components/PublicDashboard.js";
import MapView from "./components/MapView.js";
import VerifyYourMobileNumber from "./components/VerifyYourMobileNumber.js";
import ProfileForm from "./components/ProfileForm.js";
import JobPosts from "./components/JobPosts.js";
import AddJobPosts from "./components/AddJobPosts.js";
import EditJobPosts from "./components/EditJobPosts.js";
import EditProfileForm from "./components/EditProfileForm.js";
import EditProfileFormUser from "./components/EditProfileFormUser.js";
import JobForEnumerators from "./components/JobForEnumerators.js";
// import { DataProvider } from "./components/DataContext.js";
// import Enrollment from './components/Enrollment.js'

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          {/* <Route path="/membershipForm" element={<MembershipForm />}></Route> */}
          {/* <Route
              path="/membershipFormTest"
              element={<MembershipFormTest />}
            ></Route> */}
          {/* <Route path="/enrollmentForm" element={<EnrollmentForm />}></Route> */}
          {/* <Route
              path="/enrollmentFormTest"
              element={<EnrollmentFormTest />}
            ></Route> */}
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/applicantDashboard"
            element={<ApplicantDashboard />}
          ></Route>
          <Route path="/verifiedMember" element={<VerifiedMember />}></Route>
          <Route path="/certifiedMember" element={<CertifiedMember />}></Route>
          <Route
            path="/editApplicantDashboard/:id"
            element={<EditApplicantDashboard />}
          ></Route>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/termsCondition" element={<TermsCondition />}></Route>
          {/* <Route path="/enrolled" element={<Enrolled />}></Route> */}
          {/* <Route
              path="/editEnrollmentForm/:id"
              element={<EditEnrollmentForm />}
            ></Route> */}
          <Route
            path="/thankYouMembership"
            element={<ThankYouMembership />}
          ></Route>
          <Route path="/thankYouMessage" element={<ThankYouMessage />}></Route>
          <Route path="/publicDashboard" element={<PublicDashboard />}></Route>
          <Route path="/mapView" element={<MapView />}></Route>
          <Route
            path="/verifyYourMobileNumber"
            element={<VerifyYourMobileNumber />}
          ></Route>
          <Route path="/profileForm" element={<ProfileForm />}></Route>
          <Route path="/jobPosts" element={<JobPosts />}></Route>
          <Route path="/addJobPosts" element={<AddJobPosts />}></Route>
          <Route
            path="/jobForEnumerators"
            element={<JobForEnumerators />}
          ></Route>
          <Route path="/editJobPosts/:id" element={<EditJobPosts />}></Route>
          <Route
            path="/editProfileForm/:id"
            element={<EditProfileForm />}
          ></Route>
          <Route
            path="/editProfileFormUser"
            element={<EditProfileForm />}
          ></Route>
          <Route
            path="/editProfileFormUser/:encrypted_code"
            element={<EditProfileFormUser />}
          ></Route>
          {/* <Route path='/enrollment' element={<Enrollment/>}></Route> */}
        </Routes>
      </BrowserRouter>
  );
}

export default App;
