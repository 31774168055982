import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import TrackbeeCommunity from "../assets/images/Trackbee community Logo_Icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "./env";
import { useCookies } from "react-cookie";

const AdminHeader = () => {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(["token"]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  axios.defaults.withCredentials = true;

  const handleLogout = () => {
    axios
      .get(API_BASE_URL + "/auth/logOut")
      .then((res) => {
        if (res.data.Status) {
          localStorage.removeItem("valid");
          removeCookie("token");
        } else {
          console.error("Logout failed:", res.data.error);
        }
      })
      .catch((err) => {
        console.error("Logout failed:", err);
      })
      .finally(() => {
        navigate("/login");
      });
  };

  const confirmLogOut = (e) => {
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure, you want to Logout?");
    if (isConfirmed) {
      handleLogout();
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="wrapper">
      <nav className="navbar navbar-expand-md navbar-light bg-white">
        <div className="container">
          <a href="/" className="navbar-brand mr-4">
            <img
              src={TrackbeeCommunity}
              alt="Logo"
              className="brand-image img-circle elevation-3"
              style={{ opacity: ".8", width: "30px", height: "30px" }}
            />
            <span className="h5 brand-text" style={{ color: "#5e627e" }}>
              Trackbee
            </span>
            <span className="h5 brand-text" style={{ color: "#6baad0" }}>
              Community
            </span>
          </a>
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
          >
            <ul className="navbar-nav">
              <li className="nav-item mr-4">
                <NavLink
                  exact
                  to="/applicantDashboard"
                  className="nav-link"
                  activeClassName="active-link"
                >
                  Un-Verified
                </NavLink>
              </li>
              <li className="nav-item mr-4">
                <NavLink
                  exact
                  to="/verifiedMember"
                  className="nav-link"
                  activeClassName="active-link"
                >
                  Verified
                </NavLink>
              </li>
              <li className="nav-item mr-4">
                <NavLink
                  exact
                  to="/certifiedMember"
                  className="nav-link"
                  activeClassName="active-link"
                >
                  Certified
                </NavLink>
              </li>
              <li className="nav-item mr-4">
                <NavLink
                  exact
                  to="/jobPosts"
                  className="nav-link"
                  activeClassName="active-link"
                >
                  Job Posts
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <ul className="navbar-nav mr-4">
          <li className="nav-item">
            <button
              className="btn btn-blue btn-md btn-flat rounded"
              title="Logout"
              onClick={confirmLogOut}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminHeader;
