import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterOne from "./FooterOne";
import logodark from "../assets/front_images/logo-dark.png";
import logolight from "../assets/front_images/logo-light.png";
import { format } from "date-fns";
import "../styles/Style.css";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
// const API_BASE_URL = "http://localhost:3000";
import { API_BASE_URL, REACT_APP_SECRET_KEY } from "./env";
import Header from "./Header";

const JobForEnumerators = () => {
  const [hoveredIndexes, setHoveredIndexes] = useState([]);
  const [values, setValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5); // Change the number of items per page as per requirement

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/auth/getJobPostsWebsite")
      .then((res) => {
        if (res.data.Status) {
          // console.log(res.data.Result);
          setValues(res.data.Result);
          // Initialize hoveredIndexes array with false values for each job post
          setHoveredIndexes(Array(res.data.Result.length).fill(false));
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleHover = (index) => {
    setHoveredIndexes((prevHoveredIndexes) => {
      const newHoveredIndexes = [...prevHoveredIndexes];
      newHoveredIndexes[index] = !newHoveredIndexes[index];
      return newHoveredIndexes;
    });
  };

  const formatDate = (inputDate) => {
    if (inputDate === null) {
      return "N/A";
    }

    try {
      const date = new Date(inputDate);
      if (isNaN(date.getTime())) {
        return "Invalid Date";
      }

      const formattedDate = format(date, "do, MMM yyyy");
      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Error";
    }
  };

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = values.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Logic to render pagination items
  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(currentPage + 1, Math.ceil(values.length / postsPerPage));
      i++
    ) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => paginate(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const headerCharacterLimit = 100;
  const bodyCharacterLimit = 500;

  const cardStyle = {
    marginTop: "0px",
    paddingBottom: "20px",
  };

  const cardBodyStyle = {
    color: "#70747E",
    margin: "20px",
    paddingTop: "0px",
    paddingBottom: "20px",
  };


  return (
    <div>
      {/* <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          <Link className="logo" to="/">
            <img
              src={logodark}
              style={{ height: "70px" }}
              className="logo-light-mode"
              alt="logo-dark"
            />
            <img
              src={logolight}
              style={{ height: "70px" }}
              className="logo-dark-mode"
              alt="logo-light"
            />
          </Link>
          <div className="buy-button">
            <Link to="/" className="btn btn-primary">
              Back
            </Link>
          </div>
        </div>
      </header> */}
      <Header />
      <div>
        {/* <section className="bg-half bg-light d-table w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="page-next-level">
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul
                        className="breadcrumb bg-white rounded shadow mb-0 custom-breadcrumb"
                        style={{ padding: "10px" }}
                      >
                        <li className="breadcrumb-item">
                          <a href="/">TrackBee Community</a>
                        </li>
                        <li className="breadcrumb-item active">Job Posts</li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div> */}

        <section className="section">
          <div className="container mt-4">
            <div className="row justify-content-center">
              {currentPosts.length === 0 ? (
                <div
                  className="col-lg-10 col-md-7 text-center"
                  style={{ marginBottom: "16%", marginTop: "16%" }}
                >
                  <h4 className="text-muted mb-4">
                    New job posts are coming soon. Stay tuned!
                  </h4>
                </div>
              ) : (
                currentPosts.map((job, index) => {
                  // Truncate header if it exceeds the defined character limit
                  const truncatedHeader =
                    job.header.length > headerCharacterLimit
                      ? job.header.slice(0, headerCharacterLimit) + "..."
                      : job.header;

                  // Truncate body if it exceeds the defined character limit
                  const truncatedBody =
                    job.body.length > bodyCharacterLimit
                      ? job.body.slice(0, bodyCharacterLimit) + "..."
                      : job.body;

                  return (
                    <div
                      className="col-lg-10 col-md-7 d-flex justify-content-center"
                      key={index}
                    >
                      <div
                        className={`custom-form card ${
                          hoveredIndexes[index] ? "hovered" : ""
                        }`}
                        onMouseEnter={() => handleHover(index)}
                        onMouseLeave={() => handleHover(index)}
                      >
                        <div
                          className="card-body"
                          style={cardStyle}
                        >
                          <div className="d-flex align-items-center">
                            <p
                              className="card-subtitle mb-2"
                              style={{ fontWeight: "bold" }}
                            >
                              <i
                                className="fas fa-calendar-alt mr-1"
                                style={{ margin: "20px" }}
                              ></i>
                              {formatDate(job.posted_on)}
                            </p>
                          </div>
                          <div
                            className="card-title"
                            style={{
                              backgroundColor: "transparent",
                              color: "#0E1847",
                              marginLeft: "20px",
                            }}
                          >
                            {truncatedHeader}
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={cardBodyStyle}
                        >
                          <p>{truncatedBody}</p>
                        </div>
                        <div className="row justify-content-center p-2">
                          <div className="col-md-8 mb-4">
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#0E1847",
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  Contact No: +91 {job.phone}
                                </p>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#0E1847",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Email: {job.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
        <div className="col-lg-10 col-md-7 d-flex justify-content-end">
          {currentPosts.length !== 0 ? (
            <Pagination>
              <Pagination.Prev
                onClick={() =>
                  setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                }
              />
              {renderPaginationItems()}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    Math.min(
                      prevPage + 1,
                      Math.ceil(values.length / postsPerPage)
                    )
                  )
                }
              />
            </Pagination>
          ) : (
            ""
          )}
        </div>
      </div>
      <FooterOne />
    </div>
  );
};

export default JobForEnumerators;
