import React, { useEffect, useState } from "react";
import AdminHeader from "./AdminHeader";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
// import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
// const API_BASE_URL = 'http://localhost:3000';
import {API_BASE_URL, REACT_APP_SECRET_KEY} from "./env";
import { useCookies } from 'react-cookie';
import CryptoJS from "crypto-js";
import { Search } from "react-feather";

const VerifiedMember = () => {
  // axios.defaults.withCredentials = true;
  // useEffect(() => {
  //   axios
  //     .get(API_BASE_URL + "/auth/verify")
  //     .then((result) => {
  //       if (result.data.Status) {
  //         if (result.data.role === "admin") {
  //           navigate("/verifiedMember");
  //         }
  //       } else {
  //         navigate("/login");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const navigate = useNavigate();
  
  const [cookies] = useCookies(['token']);

  useEffect(() => {
    if (!cookies.token) {
      navigate("/login");
    } else {
      try {
        const decryptedToken = CryptoJS.AES.decrypt(cookies.token, REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        if (!decryptedToken) {
          throw new Error("Token decryption failed");
        }
      } catch (error) {
        console.error("Token decryption failed:", error);
        navigate("/login");
      }
    }
  }, [cookies.token, navigate]);

  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValues, setSearchValues] = useState([]);

  const stateMapping = {
    1: "Andaman Nicobar",
    2: "Andhra Pradesh",
    3: "Arunachal Pradesh",
    4: "Assam",
    5: "Bihar",
    6: "Chandigarh",
    7: "Chhattisgarh",
    8: "Dadra and Nagar Haveli and Daman and Diu",
    9: "Delhi",
    10: "Goa",
    11: "Gujarat",
    12: "Haryana",
    13: "Himachal Pradesh",
    14: "Jammu Kashmir",
    15: "Jharkhand",
    16: "Karnataka",
    17: "Kerala",
    18: "Ladakh",
    19: "Lakshadweep",
    20: "Madhya Pradesh",
    21: "Maharashtra",
    22: "Manipur",
    23: "Meghalaya",
    24: "Mizoram",
    25: "Nagaland",
    26: "Odisha",
    27: "Puducherry",
    28: "Punjab",
    29: "Rajasthan",
    30: "Sikkim",
    31: "Tamil Nadu",
    32: "Telangana",
    33: "Tripura",
    34: "Uttar Pradesh",
    35: "Uttarakhand",
    36: "West Bengal",
  };

  const genderMapping = {
    1: "Male",
    2: "Female",
    3: "Prefer not to say",
  };

  const EducationMapping = {
    1: "Class 8 Pass",
    2: "SSC",
    3: "HSC",
    4: "Diploma",
    5: "Graduate",
    6: "Postgraduate",
  };

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/auth/getVerifiedApplicants")
      .then((res) => {
        if (res.data.Status) {
          // console.log(res.data.Result);
          setValues(res.data.Result);
          setSearchValues(res.data.Result);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const result = values.filter((e) => {
  //     return e.name.toLowerCase().match(search.toLocaleLowerCase());
  //   });
  //   setSearchValues(result);
  // }, [search]);

  useEffect(() => {
    const result = values.filter((row) => {
      return Object.keys(row).some((key) => {
        const value = row[key];
        if (value === null || value === undefined) return false; // Check for null or undefined
        if (key === "created") {
          const formattedDate = formatDate(value);
          return formattedDate !== "Invalid Date" &&
                 formattedDate.toLowerCase().includes(search.toLowerCase());
        } else if (key === "gender") {
          const mappedGender = genderMapping[value];
          return mappedGender && mappedGender.toLowerCase().includes(search.toLowerCase());
        } else if (key === "education") {
          const mappedEducation = EducationMapping[value];
          return mappedEducation && mappedEducation.toLowerCase().includes(search.toLowerCase());
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(search.toLowerCase());
        } else {
          return false;
        }
      });
    });
    setSearchValues(result);
  }, [search, values]);

  const formatDate = (inputDate) => {
    if (inputDate === null) {
      return "N/A"; // Or any other placeholder for null dates
    }

    try {
      const date = new Date(inputDate);
      if (isNaN(date.getTime())) {
        return "Invalid Date"; // Handle invalid date strings
      }

      const formattedDate = format(date, "do, MMM yyyy, h:mm a");

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Error"; // Handle unexpected errors
    }
  };

  const handleVerificationChange = (applicantId, isChecked) => {
    // event.preventDefault();
    // console.log("Deleting applicant with ID:", applicantId);
    axios
      .put(API_BASE_URL + "/auth/givePermission/" + applicantId)
      .then((res) => {
        if (res.data.Status) {
          window.location.reload("/verifiedMember");
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleCertificationChange = (applicantId, isChecked) => {
    // event.preventDefault();
    // console.log("Deleting applicant with ID:", applicantId);
    axios
      .put(API_BASE_URL + "/auth/certified/" + applicantId)
      .then((res) => {
        if (res.data.Status) {
          window.location.reload("/verifiedMember");
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };

  //   const handleDeleteApplicant = (event, applicantId) => {
  //     event.preventDefault();
  //     console.log("Deleting applicant with ID:", applicantId);
  //   };

  const confirmDeleteJobPost = (e, jobId) => {
    e.preventDefault();

    // Display confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure, you want to delete this Verified Member?"
    );

    // If confirmed, call handleDeleteJobPosts
    if (isConfirmed) {
      handleDeleteApplicant(jobId);
    }
  };

  const handleDeleteApplicant = (jobId) => {
    axios
      .delete(API_BASE_URL + "/auth/deleteApplicant/" + jobId)
      .then((res) => {
        if (res.data.Status) {
          window.location.reload("/verifiedMember");
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "50px", // Set the height as needed
      },
    },
    headCells: {
      style: {
        fontWeight: "bold", // Make the heading text bold
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        fontSize: "14px", // Adjust the font size as needed
        height: "120px",
      },
    },
  };

  const columns = [
    {
      name: "Sl no",
      selector: (row, index) => index + 1,
      sortable: true,
      maxWidth: "5px",
    },
    {
      name: "Applicant",
      cell: (row) => (
        <div>
          <b>Name: </b>
          {row.name}
          <br />
          <span>
            <b>Year of birth: </b>
            {row.year_of_birth}
          </span>
          <br />
          <span>
            <b>Gender: </b>
            {genderMapping[row.gender]}
          </span>
          <br />
          <span>
            <b>Age: </b>
            {row.age}
          </span>
        </div>
      ),
      sortable: true,
      maxWidth: "200px",
    },
    {
      name: "Contacts",
      cell: (row) => (
        <div>
          <b>Phone: </b>
          {row.phone_number}
          <br />
          <b>WhatsApp Number: </b>
          {row.whatsapp_phone_number}
          <br />
          <span>
            <b>Email: </b>
            {row.email_id}
          </span>
        </div>
      ),
      sortable: true,
      maxWidth: "300px",
    },
    {
      name: "Location",
      cell: (row) => (
        <div>
          <b>State: </b>
          {row.state}
          <br />
          <b>District: </b>
          {row.district}
          <br />
          <span>
            <b>City: </b>
            {capitalizeFirstLetter(row.city)}
          </span>
        </div>
      ),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Experience",
      cell: (row) => (
        <div>
          <b>Year of Experience: </b>
          {row.experience}
          <br />
          <span>
            <b>Highest Education: </b>
            {EducationMapping[row.education]}
          </span>
          <br />
          <span>
            <b>Use Trackbee Mobile Application: </b>
            {row.previous_trackbee_experience === "1" ? "Yes" : "No"}
          </span>
        </div>
      ),
      maxWidth: "180px",
    },
    {
      name: "Applied On",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {formatDate(row.created) !== "Invalid Date"
            ? formatDate(row.created)
            : ""}
        </div>
      ),
      sortable: true,
      maxWidth: "300px",
      center: true,
    },
    {
      name: "Give_Permission",
      cell: (row) => (
        <>
          <div>
            <input
              type="checkbox"
              checked={row.give_permission === "1"}
              disabled={row.give_permission === "1"}
              onChange={() => {
                const confirmed = window.confirm(
                  "Are you sure you want to grant permission to display your details on our public dashboard?"
                );
                if (confirmed) {
                  handleVerificationChange(row.id, row.give_permission !== "1");
                }
              }}
            />
          </div>
        </>
      ),
      sortable: true,
      center: true,
      maxWidth: "200px",
    },
    {
      name: "Certified",
      cell: (row) => (
        <>
          <div>
            <input
              type="checkbox"
              checked={row.certified === "1"}
              disabled={row.certified === "1"}
              onChange={() => {
                const confirmed = window.confirm(
                  "Are you sure this enumerator is certified?"
                );
                if (confirmed) {
                  handleVerificationChange(row.id, row.certified !== "1");
                }
              }}
            />
          </div>
        </>
      ),
      sortable: true,
      center: true,
      maxWidth: "150px",
    },
    // {
    //   name: "Is_Enrolled",
    //   cell: (row) => (
    //      row.enrolled === "1" ? "Yes" : "No"
    //   ),
    //   sortable: true,
    //   center: true,
    //   maxWidth: "200px",
    // },
    {
      name: "ACTION",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <Link to={"/editProfileForm/" + row.id} className="action-link me-3 ">
            <i className="fas fa-edit"></i>
          </Link>
          <Link
            to={"/deleteApplicant/" + row.id}
            onClick={(e) => confirmDeleteJobPost(e, row.id)}
          >
            <i className="fas fa-trash text-danger"></i>
          </Link>
        </div>
      ),
      sortable: true,
      center: true,
      maxwidth: "50px",
    },
  ];

  const csvHeaders = [
    { label: "Applicant", key: "name" },
    { label: "Phone", key: "phone_number" },
    { label: "EMAIL", key: "email_id" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Experience", key: "experience" },
    { label: "Education", key: "education" },
    { label: "Applied on", key: "created" },
    { label: "Is_Verified", key: "verified" },
  ];

  const modifiedData = searchValues.map((row) => ({
    ...row,
    verified: row.verified === "1" ? "Verified" : "Not Verified",
    gender: genderMapping[row.gender],
    state: stateMapping[row.state],
    city: capitalizeFirstLetter(row.city),
    created: formatDate(row.created),
    education: EducationMapping[row.education],
  }));

  const csvString = `${csvHeaders
    .map((header) => `"${header.label}"`)
    .join(",")}\n${modifiedData
    .map((row) => csvHeaders.map((header) => `"${row[header.key]}"`).join(","))
    .join("\n")}`;

  // axios.defaults.withCredentials = true;

  // useEffect(() => {
  //   axios
  //     .get(API_BASE_URL + "/auth/verify")
  //     .then((result) => {
  //       if (result.data.Status) {
  //         if (result.data.role === "admin") {
  //           navigate("/verifiedMember");
  //         }
  //       } else {
  //         navigate("/login");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);
  return (
    <div>
      <AdminHeader />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12">
            <div className="card shadow">
              <div
                className="card-body p-1"
                style={{ backgroundColor: "#5e627e" }}
              >
                <h5 className="card-title text-light mt-1">Applicant list</h5>
                {/* <CSVLink
                  data={modifiedData}
                  headers={csvHeaders}
                  filename={"verified_applicant_data.csv"}
                  className="btn btn-info btn-sm btn-flat float-right"
                >
                  <i className="fas fa-flag" /> Report
                </CSVLink> */}
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="table-responsive shadow p-3">
              <DataTable
                columns={columns}
                data={searchValues}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="700px"
                customStyles={customStyles}
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <div className="input-group">
                        <span className="input-group-text">
                          <Search size={16} />
                        </span>
                        <input
                          type="text"
                          placeholder="Search here"
                          className="form-control"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedMember;
