const ENVIRONMENT = "PROD"; // LOCAL/PROD

let base_url = "";
switch (ENVIRONMENT) {
  case "LOCAL":
    base_url = "http://localhost:3000";
    break;
  case "PROD":
    base_url = "http://13.234.209.96:3000";
    break;
  default:
    throw new Error("Invalid environment specified");
}

export const API_BASE_URL = base_url;
export const REACT_APP_SECRET_KEY = "react_secret_key";
