import React from "react";
import logodark from "../assets/front_images/logo-dark.png";
import logolight from "../assets/front_images/logo-light.png";
import { Link } from "react-router-dom";

const HeaderOne = () => {
  function toggleMenu() {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  }

  return (
    <header id="topnav" className="defaultscroll sticky">
      <div className="container">
        <Link className="logo" to="/">
          <img
            src={logodark}
            style={{ height: "70px" }}
            className="logo-light-mode"
            alt="logo-dark"
          />
          <img
            src={logolight}
            style={{ height: "70px" }}
            className="logo-dark-mode"
            alt="logo-light"
          />
        </Link>
        <div className="buy-button">
          <Link to="/" className="btn btn-primary">
            Home
          </Link>
        </div>

        {/* <div className="menu-extras">
          <div className="menu-item">
            <a className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
          </div>
        </div> */}
        {/* <div id="navigation">
        <ul className="navigation-menu">
          <li style={{ marginRight: "10px" }}>
            <a href="#home" className="sub-menu-item">
              Home
            </a>
          </li>
          <li style={{ marginRight: "10px" }}>
            <a href="#community" className="sub-menu-item">
              Community
            </a>
          </li>
          <li style={{ marginRight: "10px" }}>
            <a href="#event" className="sub-menu-item">
              Event
            </a>
          </li>
          <li style={{ marginRight: "10px" }}>
            <a href="#faq" className="sub-menu-item">
              FAQ
            </a>
          </li>
          <li>
            <a href="#contact" className="sub-menu-item">
              Contact
            </a>
          </li>
        </ul>
        <div className="buy-menu-btn d-none">
          <a href="membership/apply" className="btn btn-primary">
            Apply Now
          </a>
          <div className="buy-menu-btn d-none">
          <a href="/" className="btn btn-outline-primary">
            Home
          </a>
        </div>
        </div>
      </div> */}
      </div>
    </header>
  );
};

export default HeaderOne;
