import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderOne from "./HeaderOne";
import FooterOne from "./FooterOne";
// import { useNavigate } from "react-router-dom";
import {
  Book,
  Calendar,
  Circle,
  Clock,
  Mail,
  Map,
  MapPin,
  MessageCircle,
  Phone,
  User,
} from "react-feather";
import axios from "axios";
// const API_BASE_URL = "http://localhost:3000";
import {API_BASE_URL, REACT_APP_SECRET_KEY} from "./env";

const EditApplicantDashboard = () => {
  const { id } = useParams();

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios
      .get(API_BASE_URL + "/verify")
      .then((result) => {
        if (result.data.Status) {
          if (result.data.role === "admin") {
            navigate("/editApplicantDashboard/:id");
          }
        } else {
          navigate("/login");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const startingYear = new Date().getFullYear() - 18;
  const yearOptions = [];

  for (let i = 0; i < 53; i++) {
    const year = startingYear - i;
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const generateRandomCode = () => {
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    const randomCode = Array.from(array)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return randomCode;
  };
  const unique_code = generateRandomCode();

  // const navigate = useNavigate();

  const [values, setValues] = useState([]);
  const [errors, setErrors] = useState({});

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    const match = userAgent.match(/(Chrome|Firefox|Safari|Edge)\/(\S+)/);

    if (match) {
      const browserName = match[1];
      const browserVersion = match[2];
      return `${browserName} | ${browserVersion}`;
    } else {
      return "Unknown Browser";
    }
  };

  const getDeviceInfo = () => {
    const platform = navigator.platform;
    return platform.includes("Win")
      ? "Windows"
      : platform.includes("Android")
      ? "Android"
      : "iOS";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const browser = getBrowserInfo();
    const device = getDeviceInfo();

    setValues({
      ...values,
      [name]: value,
      encrypted_code: values.encrypted_code,
      browser: browser,
      device: device,
    });
  };

  // console.log("values:", values);

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/auth/getApplicants/" + id)
      .then((res) => {
        if (res.data.Status) {
          // console.log(res.data.Result[0]);
          setValues(res.data.Result[0]);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.name) {
      validationErrors.name = "Please provide input";
    }
    if (!values.email_id) {
      validationErrors.email_id = " ";
    } else if (!/\S+@\S+\.\S+/.test(values.email_id)) {
      validationErrors.email_id = "Email is not valid";
    }
    if (!values.phone_number) {
      validationErrors.phone_number = "Please provide input";
    }
    if (!values.gender) {
      validationErrors.gender = "Please provide input";
    }
    if (!values.previous_trackbee_experience) {
      validationErrors.previous_trackbee_experience = "Please provide input";
    }
    if (!values.state) {
      validationErrors.state = "Please provide input";
    }
    if (!values.city) {
      validationErrors.city = "Please provide input";
    }
    if (!values.year_of_birth) {
      validationErrors.year_of_birth = "Please provide input";
    }
    if (!values.education) {
      validationErrors.education = "Please provide input";
    }
    if (!values.experience) {
      validationErrors.experience = "Please provide input";
    }
    if (!values.join_reason) {
      validationErrors.join_reason = "Please provide input";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // alert("Form Submitted successfully")
      axios
        .put(API_BASE_URL + "/auth/editMember/" + id, values)
        .then((res) => {
          if (res.data.Status) {
            // console.log(res.data);
            // alert(
            //   "Successfully updated Applicant details."
            // );
            // window.location.reload();
            navigate("/applicantDashboard");
          } else {
            console.log(res.data.Error);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <HeaderOne />
      <div>
        <section className="bg-half bg-light d-table w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="page-next-level">
                  <h4 className="title">
                    Application Form for Trackbee Certification
                  </h4>
                  <p>
                    To participate in the Trackbee certification training
                    program, fill out and submit the application form. Our team
                    will review the submissions, choose participants for the
                    program, and notify the chosen candidates of their
                    acceptance by sending an invitation
                  </p>
                  <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul
                        className="breadcrumb bg-white rounded shadow mb-0"
                        style={{ padding: "10px" }}
                      >
                        <li className="breadcrumb-item">
                          <a href="/">TrackBee Community</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Apply
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-7">
                <div className="custom-form border-0" style={{ border: "0" }}>
                  <div className="card-body">
                    <form
                      className="rounded shadow p-4"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Name
                              <span className="text-danger">
                                *{" "}
                                {errors.name && (
                                  <span className="text-danger small">
                                    {errors.name}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_name" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <User className="fea icon-sm icons" />
                              <input
                                name="name"
                                id="applicant_name"
                                type="text"
                                className="form-control ps-5 validation-require"
                                placeholder="Enter name"
                                maxLength={200}
                                autoComplete="off"
                                value={values.name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Gender
                              <span className="text-danger">
                                *{" "}
                                {errors.gender && (
                                  <span className="text-danger small">
                                    {errors.gender}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_gender" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Circle className="fea icon-sm icons" />
                              <select
                                name="gender"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_gender"
                                value={values.gender}
                                onChange={handleInputChange}
                              >
                                <option value>Select your gender</option>
                                <option value={1}>Male</option>
                                <option value={2}>Female</option>
                                <option value={4}>Prefer not to say</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Email{" "}
                              {errors.email_id && (
                                <span className="text-danger small">
                                  {errors.email_id}
                                </span>
                              )}
                              <span className="small font-noraml text-danger error_applicant_email" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Mail className="fea icon-sm icons" />
                              <input
                                name="email_id"
                                id="applicant_email"
                                type="email"
                                value={values.email_id}
                                className="form-control ps-5"
                                placeholder="Enter email"
                                maxLength={200}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mobile
                              <span className="text-danger">
                                *
                                {errors.phone_number && (
                                  <span className="text-danger small">
                                    {errors.phone_number}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_phone_number" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Phone className="fea icon-sm icons" />
                              <input
                                name="phone_number"
                                id="applicant_phone_number"
                                className="form-control ps-5 "
                                placeholder="Enter mobile number"
                                maxLength={10}
                                autoComplete="off"
                                value={values.phone_number}
                                onChange={handleInputChange}
                                onKeyPress={(event) => {
                                  // Allow only numeric input (0-9) and prevent other characters
                                  const isValidInput = /[0-9]/.test(event.key);
                                  if (!isValidInput) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Have you used the Trackbee mobile application
                              earlier?
                              <span className="text-danger">
                                *{" "}
                                {errors.previous_trackbee_experience && (
                                  <span className="text-danger small">
                                    {errors.previous_trackbee_experience}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_previous_trackbee_experience" />
                            </label>
                            <div className="row">
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="previous_trackbee_experience"
                                    id="previous_trackbee_experience_yes"
                                    defaultValue={1}
                                    checked={
                                      values.previous_trackbee_experience ===
                                      "1"
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="previous_trackbee_experience_yes"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </div>
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="previous_trackbee_experience"
                                    id="previous_trackbee_experience_no"
                                    defaultValue={0}
                                    checked={
                                      values.previous_trackbee_experience ===
                                      "0"
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="previous_trackbee_experience_no"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              State
                              <span className="text-danger">
                                *
                                {errors.state && (
                                  <span className="text-danger small">
                                    {errors.state}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_state" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Map className="fea icon-sm icons" />
                              <select
                                name="state"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_state"
                                value={values.state}
                                onChange={handleInputChange}
                              >
                                <option value>Select your state</option>
                                <option value={1}>Andaman Nicobar</option>
                                <option value={2}>Andhra Pradesh</option>
                                <option value={3}>Arunachal Pradesh</option>
                                <option value={4}>Assam</option>
                                <option value={5}>Bihar</option>
                                <option value={6}>Chandigarh</option>
                                <option value={7}>Chhattisgarh</option>
                                <option value={8}>
                                  Dadra and Nagar Haveli and Daman and Diu
                                </option>
                                <option value={9}>Delhi</option>
                                <option value={10}>Goa</option>
                                <option value={11}>Gujarat</option>
                                <option value={12}>Haryana</option>
                                <option value={13}>Himachal Pradesh</option>
                                <option value={14}>Jammu Kashmir</option>
                                <option value={15}>Jharkhand</option>
                                <option value={16}>Karnataka</option>
                                <option value={17}>Kerala</option>
                                <option value={18}>Ladakh</option>
                                <option value={19}>Lakshadweep</option>
                                <option value={20}>Madhya Pradesh</option>
                                <option value={21}>Maharashtra</option>
                                <option value={22}>Manipur</option>
                                <option value={23}>Meghalaya</option>
                                <option value={24}>Mizoram</option>
                                <option value={25}>Nagaland</option>
                                <option value={26}>Odisha</option>
                                <option value={27}>Puducherry</option>
                                <option value={28}>Punjab</option>
                                <option value={29}>Rajasthan</option>
                                <option value={30}>Sikkim</option>
                                <option value={31}>Tamil Nadu</option>
                                <option value={32}>Telangana</option>
                                <option value={33}>Tripura</option>
                                <option value={34}>Uttar Pradesh</option>
                                <option value={35}>Uttarakhand</option>
                                <option value={36}>West Bengal</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              City
                              <span className="text-danger">
                                *{" "}
                                {errors.city && (
                                  <span className="text-danger small">
                                    {errors.city}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_city" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MapPin className="fea icon-sm icons" />
                              <input
                                name="city"
                                id="applicant_city"
                                type="text"
                                className="form-control ps-5 validation-require"
                                placeholder="Enter city"
                                maxLength={200}
                                autoComplete="off"
                                value={values.city}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Year of birth
                              <span className="text-danger">
                                *
                                {errors.year_of_birth && (
                                  <span className="text-danger small">
                                    {errors.year_of_birth}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_applicant_birth_year" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Calendar className="fea icon-sm icons" />
                              <select
                                name="year_of_birth"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_birth_year"
                                value={values.year_of_birth}
                                onChange={handleInputChange}
                              >
                                <option value="Select year">Select year</option>
                                {yearOptions}
                              </select>
                            </div>
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Highest education
                              <span className="text-danger">
                                *
                                {errors.education && (
                                  <span className="text-danger small">
                                    {errors.education}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_applicant_education" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Book className="fea icon-sm icons" />
                              <select
                                name="education"
                                className="form-control custom-select text-muted ps-5 validation-require"
                                id="applicant_education"
                                value={values.education}
                                onChange={handleInputChange}
                              >
                                <option value>Select qualification</option>
                                <option value={1}>Class 8 Pass</option>
                                <option value={2}>SSC</option>
                                <option value={3}>HSC</option>
                                <option value={4}>Diploma</option>
                                <option value={5}>Graduate</option>
                                <option value={6}>Postgraduate</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Total year of experience
                              <span className="text-danger">
                                *{" "}
                                {errors.experience && (
                                  <span className="text-danger small">
                                    {errors.experience}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_applicant_experience" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Clock className="fea icon-sm icons" />
                              <input
                                name="experience"
                                id="applicant_experience"
                                type="text"
                                className="form-control ps-5 validation-require number"
                                placeholder="Enter year"
                                maxLength={2}
                                autoComplete="off"
                                value={values.experience}
                                onChange={handleInputChange}
                                onKeyPress={(event) => {
                                  // Allow only numeric input (0-9) and prevent other characters
                                  const isValidInput = /[0-9]/.test(event.key);
                                  if (!isValidInput) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Why do you want to join the Trackbee community and
                              get certified?
                              <span className="text-danger">
                                *{" "}
                                {errors.join_reason && (
                                  <span className="text-danger small">
                                    {errors.join_reason}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_join_reason" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MessageCircle className="fea icon-sm icons" />
                              <textarea
                                name="join_reason"
                                id="join_reason"
                                rows={4}
                                className="form-control ps-5 validation-require"
                                placeholder="Comments"
                                value={values.join_reason}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-4 d-flex justify-content-center">
                          <button
                            id="membership_apply"
                            className="submitBnt btn btn-primary"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FooterOne />
    </div>
  );
};

export default EditApplicantDashboard;
