import React, { useEffect, useState } from "react";
import logodark from "../assets/front_images/logo-dark.png";
import logolight from "../assets/front_images/logo-light.png";
import { Link, useNavigate } from "react-router-dom";
import { ArrowUp, Facebook, Linkedin, Twitter } from "react-feather";
import anychart from "anychart";
import axios from "axios";
// import { useData } from "./DataContext.js";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "anychart/dist/css/anychart-ui.min.css";
import "../styles/Style.css";
// const API_BASE_URL = 'http://localhost:3000';
import { API_BASE_URL, REACT_APP_SECRET_KEY } from "./env";
import Header from "./Header";

const MapView = () => {
  const currentYear = new Date().getFullYear();
  const [values, setValues] = useState([]);
  const [stateValues, setStateValues] = useState([]);
  // const { setSharedDataValue } = useData(); // Use the context to set shared data
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function toggleMenu() {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  }

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/auth/getEnrolledPublicDashboard")
      .then((res) => {
        if (res.data.Status) {
          setValues(res.data.Result);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [stateCounts, setStateCounts] = useState({});

  useEffect(() => {
    // Calculate state counts when values change
    const calculateStateCounts = () => {
      const counts = {};

      // Iterate over the values array and count occurrences of each state
      values.forEach((value) => {
        const state = value.state;

        // Update the count for the state
        counts[state] = (counts[state] || 0) + 1;
      });

      // Update the state with the counts
      setStateCounts(counts);
    };

    calculateStateCounts();

    const countsValues = Object.values(stateCounts);
    const minValue = Math.min(...countsValues);
    const maxValue = Math.max(...countsValues);
  }, [values]);

  // console.log(stateCounts);

  useEffect(() => {
    // Create a map
    var map = anychart.map();

    const countsValues = Object.values(stateCounts);
    const minValue = Math.min(...countsValues);
    const maxValue = Math.max(...countsValues);

    // Create a data set
    var dataSet = anychart.data.set([
      { id: "IN.AN", value: stateCounts["Andaman Nicobar"] || 0 },
      { id: "IN.AP", value: stateCounts["Andhra Pradesh"] || 0 },
      { id: "IN.AR", value: stateCounts["Arunachal Pradesh"] || 0 },
      { id: "IN.AS", value: stateCounts["Assam"] || 0 },
      { id: "IN.BR", value: stateCounts["Bihar"] || 0 },
      { id: "IN.CH", value: stateCounts["Chandigarh"] || 0 },
      { id: "IN.CT", value: stateCounts["Chhattisgarh"] || 0 },
      { id: "IN.DN", value: stateCounts["Dadra & Nagar Haveli"] || 0 },
      { id: "IN.DD", value: stateCounts["Daman & Diu"] || 0 },
      { id: "IN.DL", value: stateCounts["Delhi"] || 0 },
      { id: "IN.GA", value: stateCounts["Goa"] || 0 },
      { id: "IN.GJ", value: stateCounts["Gujarat"] || 0 },
      { id: "IN.HR", value: stateCounts["Haryana"] || 0 },
      { id: "IN.HP", value: stateCounts["Himachal Pradesh"] || 0 },
      { id: "IN.JH", value: stateCounts["Jharkhand"] || 0 },
      { id: "IN.KA", value: stateCounts["Karnataka"] || 0 },
      { id: "IN.KL", value: stateCounts["Kerala"] || 0 },
      { id: "IN.LD", value: stateCounts["Lakshadweep"] || 0 },
      { id: "IN.MP", value: stateCounts["Madhya Pradesh"] || 0 },
      { id: "IN.MH", value: stateCounts["Maharashtra"] || 0 },
      { id: "IN.MNL", value: stateCounts["Manipur"] || 0 },
      { id: "IN.ML", value: stateCounts["Meghalaya"] || 0 },
      { id: "IN.MZ", value: stateCounts["Mizoram"] || 0 },
      { id: "IN.NL", value: stateCounts["Nagaland"] || 0 },
      { id: "IN.OR", value: stateCounts["Odisha"] || 0 },
      { id: "IN.PY", value: stateCounts["Puducherry"] || 0 },
      { id: "IN.PB", value: stateCounts["Punjab"] || 0 },
      { id: "IN.RJ", value: stateCounts["Rajasthan"] || 0 },
      { id: "IN.SK", value: stateCounts["Sikkim"] || 0 },
      { id: "IN.TN", value: stateCounts["Tamil Nadu"] || 0 },
      { id: "IN.TR", value: stateCounts["Tripura"] || 0 },
      { id: "IN.UP", value: stateCounts["Uttar Pradesh"] || 0 },
      { id: "IN.UT", value: stateCounts["Uttarakhand"] || 0 },
      { id: "IN.WB", value: stateCounts["West Bengal"] || 0 },
      { id: "IN.TG", value: stateCounts["Telangana"] || 0 },
      { id: "IN.JK", value: stateCounts["Jammu & Kashmir"] || 0 },
      { id: "IN.LA", value: stateCounts["Ladakh"] || 0 },
    ]);

    // Create a choropleth series
    var series = map.choropleth(dataSet);

    // Set map color settings
    series.colorScale(anychart.scales.linearColor("#deebf7", "#3182bd"));
    series.hovered().fill("#4796C5");
    series.selected().fill("#363B5F").stroke(anychart.color.darken("#363B5F"));

    // Add labels to display the enumerator counts
    // series.labels().enabled(true).format("{%value}");

    // series
    //   .tooltip()
    //   .useHtml(true)
    //   .format(function () {
    //     return (
    //       '<span style="color: #d9d9d9">Enumerator Count</span>: ' + // Change "Respondent Value" to "User Value"
    //       parseFloat(this.value).toLocaleString() +
    //       "<br/>" +
    //       '<span style="color: #d9d9d9">ID</span>: ' +
    //       this.id
    //     );
    //   });
    series
      .tooltip()
      .useHtml(true)
      .format(function () {
        return (
          // '<div style="background-color: #363B5F; padding: 10px;">' + // Replace #YourBackgroundColor with the desired background color
          '<span style="color: #d9d9d9">Enumerator Count</span>: ' + // Change "Respondent Value" to "User Value"
          parseFloat(this.value).toLocaleString() +
          "<br/>" +
          '<span style="color: #d9d9d9">ID</span>: ' +
          this.id +
          "</div>"
        );
      });
    //   series
    // .tooltip()
    // .useHtml(true)
    // .format(function () {
    //   return (
    //     '<span style="color: #d9d9d9">State</span>: ' + this.id + "<br/>" +
    //     '<span style="color: #d9d9d9">Enumerator Count</span>: ' +
    //     parseFloat(this.value).toLocaleString() +
    //     "<br/>" +
    //     '<span style="color: #d9d9d9">Some Other Information</span>: ' +
    //     "Additional details here"
    //   );
    // });

    const stateIdToName = {
      "IN.AN": "Andaman Nicobar",
      "IN.AP": "Andhra Pradesh",
      "IN.AR": "Arunachal Pradesh",
      "IN.AS": "Assam",
      "IN.BR": "Bihar",
      "IN.CH": "Chandigarh",
      "IN.CT": "Chhattisgarh",
      "IN.DN": "Dadra & Nagar Haveli",
      "IN.DD": "Daman & Diu",
      "IN.DL": "Delhi",
      "IN.GA": "Goa",
      "IN.GJ": "Gujarat",
      "IN.HR": "Haryana",
      "IN.HP": "Himachal Pradesh",
      "IN.JH": "Jharkhand",
      "IN.KA": "Karnataka",
      "IN.KL": "Kerala",
      "IN.LD": "Lakshadweep",
      "IN.MP": "Madhya Pradesh",
      "IN.MH": "Maharashtra",
      "IN.MNL": "Manipur",
      "IN.ML": "Meghalaya",
      "IN.MZ": "Mizoram",
      "IN.NL": "Nagaland",
      "IN.OR": "Odisha",
      "IN.PY": "Puducherry",
      "IN.PB": "Punjab",
      "IN.RJ": "Rajasthan",
      "IN.SK": "Sikkim",
      "IN.TN": "Tamil Nadu",
      "IN.TR": "Tripura",
      "IN.UP": "Uttar Pradesh",
      "IN.UT": "Uttarakhand",
      "IN.WB": "West Bengal",
      "IN.TG": "Telangana",
      "IN.JK": "Jammu & Kashmir",
      "IN.LA": "Ladakh",
    };

    series.listen("pointClick", function (e) {
      // Extract the clicked state ID
      const clickedStateId = e.point.get("id");

      // Map the state ID to the state name
      const clickedStateName = stateIdToName[clickedStateId] || "Unknown";

      // Fetch data based on the clicked state
      fetchDataForState(clickedStateName);
    });

    const fetchDataForState = async (state) => {
      try {
        setLoading(true);
        const response = await axios.get(
          API_BASE_URL + `/auth/getDataForState/${state}`
        );

        // Process the response data as needed
        // console.log(response.data.data);
        setStateValues(response.data.data);
        // setSharedDataValue(response.data.data);
        setTimeout(() => {
          setLoading(false); // Set loading state to false
          navigate("/publicDashboard", {
            state: response.data.data,
          });
        }, 4000);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    // var scale = anychart.scales.ordinalColor([
    //   { from: 0, to: minValue },
    //   { from: minValue + 1, to: minValue +500 },
    //   { from: minValue + 501, to: minValue + 600 },
    //   { from: minValue + 601, to: minValue + 700 },
    //   { from: minValue + 701, to: minValue + 800 },
    //   { greater: minValue + 801 },
    // ]);
    var scale = anychart.scales.ordinalColor([
      { from: 0, to: 250 },
      { from: 250 + 1, to: 500 },
      { from: 501, to: 750 },
      { from: 751, to: 1000 },
      { from: 1001, to: 1250 },
      { greater: 1250 },
    ]);

    // Add white color for the case when count is 0
    scale.colors([
      "#ffffff", // White
      "#c2e2fb",
      "#94c8f5",
      "#66aee0",
      "#3884da",
      "#363B5F", // Dark Blue
    ]);

    var colorRange = map.colorRange();
    colorRange.enabled(true).padding([0, 0, 20, 0]);
    colorRange
      .ticks()
      .enabled(true)
      .stroke("3 #ffffff")
      .position("center")
      .length(7);
    colorRange.colorLineSize(5);
    colorRange.marker().size(7);
    colorRange
      .labels()
      .fontSize(11)
      .padding(3, 0, 0, 0)
      .format(function () {
        var range = this.colorRange;
        var name;
        if (isFinite(range.start + range.end)) {
          name = range.start + " - " + range.end;
        } else if (isFinite(range.start)) {
          name = "More than " + range.start;
        } else {
          name = "Less than " + range.end;
        }
        return name;
      });

    series.colorScale(scale);

    // Set geo data, you can find this map in our geo maps collection
    // https://cdn.anychart.com/#maps-collection
    map.geoData(anychart.maps["india"]);

    // Set map container id (div)
    map.container("container");

    // Initiate map drawing
    map.draw();

    // Cleanup on component unmount
    return () => {
      map.dispose();
    };
  }, [stateCounts]);

  // console.log(stateValues);

  return (
    <>
      <div className="wrapper">
        {/* Header */}
        {/* <header id="topnav" className="defaultscroll sticky border-bottom">
          <div className="container">
            <Link className="logo" to="/publicDashboard">
              <img
                src={logodark}
                style={{ height: "70px" }}
                className="logo-light-mode"
                alt="logo-dark"
              />
              <img
                src={logolight}
                style={{ height: "70px" }}
                className="logo-dark-mode"
                alt="logo-light"
              />
            </Link>
            <div className="menu-extras">
              <div className="menu-item">
                <button
                  className="navbar-toggle"
                  id="isToggle"
                  onClick={() => toggleMenu()}
                >
                  <div className="lines">
                    <span />
                    <span />
                    <span />
                  </div>
                </button>
              </div>
            </div>
            <div id="navigation" className="align-items-end">
              <ul className="navigation-menu">
                <li>
                  <Link to="/PublicDashboard">Profile Listing</Link>
                </li>
              </ul>
            </div>
          </div>
        </header> */}

        <Header />

        {/* Main Content */}
        <main>
          <section className="section pb-0">
            <div className="container">
              {loading && (
                <div className="loading-overlay">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    className="loading-spinner"
                  />
                  loading...
                </div>
              )}
              <div className="row">
                <div className="col-lg-1 col-md-12"></div>
                <div
                  className="col-lg-11 col-md-12"
                  id="container"
                  style={{
                    height: "90vh",
                    width: "120%", // Set the width to 100% or any other value you prefer
                  }}
                  data-tip
                  data-for="chartTooltip"
                >
                  <Tooltip id="chartTooltip" effect="solid">
                    AnyChart - JavaScript Charts designed to be embedded and
                    integrated, v8.12.0.1936
                  </Tooltip>
                </div>
                {/* <div
                  className="col-lg-5 col-md-12"
                  style={{ maxHeight: 550, overflowY: "scroll" }}
                >
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>State</th>
                          <th>Enumerator Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Andaman and Nicobar Islands</td>
                          <td className="text-center">
                            {stateCounts["Andaman Nicobar"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Andhra Pradesh</td>
                          <td className="text-center">
                            {stateCounts["Andhra Pradesh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Arunachal Pradesh</td>
                          <td className="text-center">
                            {stateCounts["Arunachal Pradesh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Assam</td>
                          <td className="text-center">
                            {stateCounts["Assam"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Bihar</td>
                          <td className="text-center">
                            {stateCounts["Bihar"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Chandigarh</td>
                          <td className="text-center">
                            {stateCounts["Chandigarh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Chhattisgarh</td>
                          <td className="text-center">
                            {stateCounts["Chhattisgarh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Dadra &amp; Nagar Haveli</td>
                          <td className="text-center">
                            {stateCounts["Dadra & Nagar Haveli"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Daman &amp; Diu</td>
                          <td className="text-center">
                            {stateCounts["Daman & Diu"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Delhi</td>
                          <td className="text-center">
                            {stateCounts["Delhi"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Goa</td>
                          <td className="text-center">
                            {stateCounts["Goa"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>Gujarat</td>
                          <td className="text-center">
                            {stateCounts["Gujarat"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>Haryana</td>
                          <td className="text-center">
                            {stateCounts["Haryana"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>Himachal Pradesh</td>
                          <td className="text-center">
                            {stateCounts["Himachal Pradesh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>15</td>
                          <td>Jammu and Kashmir</td>
                          <td className="text-center">
                            {stateCounts["Jammu and Kashmir"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>16</td>
                          <td>Jharkhand</td>
                          <td className="text-center">
                            {stateCounts["Jharkhand"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>17</td>
                          <td>Karnataka</td>
                          <td className="text-center">
                            {stateCounts["Karnataka"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>18</td>
                          <td>Kerala</td>
                          <td className="text-center">
                            {stateCounts["Kerala"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>19</td>
                          <td>Ladakh</td>
                          <td className="text-center">
                            {stateCounts["Ladakh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>20</td>
                          <td>Lakshadweep</td>
                          <td className="text-center">
                            {stateCounts["Lakshadweep"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>21</td>
                          <td>Madhya Pradesh</td>
                          <td className="text-center">
                            {stateCounts["Madhya Pradesh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>22</td>
                          <td>Maharashtra</td>
                          <td className="text-center">
                            {stateCounts["Maharashtra"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>23</td>
                          <td>Manipur</td>
                          <td className="text-center">
                            {stateCounts["Manipur"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>24</td>
                          <td>Meghalaya</td>
                          <td className="text-center">
                            {stateCounts["Meghalaya"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>25</td>
                          <td>Mizoram</td>
                          <td className="text-center">
                            {stateCounts["Mizoram"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>26</td>
                          <td>Nagaland</td>
                          <td className="text-center">
                            {stateCounts["Nagaland"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>27</td>
                          <td>Odisha</td>
                          <td className="text-center">
                            {stateCounts["Odisha"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>28</td>
                          <td>Punjab</td>
                          <td className="text-center">
                            {stateCounts["Punjab"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>29</td>
                          <td>Rajasthan</td>
                          <td className="text-center">
                            {stateCounts["Rajasthan"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>30</td>
                          <td>Sikkim</td>
                          <td className="text-center">
                            {stateCounts["Sikkim"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>31</td>
                          <td>Tamil Nadu</td>
                          <td className="text-center">
                            {stateCounts["Tamil Nadu"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>32</td>
                          <td>Telangana</td>
                          <td className="text-center">
                            {stateCounts["Telangana"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>33</td>
                          <td>Tripura</td>
                          <td className="text-center">
                            {stateCounts["Tripura"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>34</td>
                          <td>Uttar Pradesh</td>
                          <td className="text-center">
                            {stateCounts["Uttar Pradesh"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>35</td>
                          <td>Uttarakhand</td>
                          <td className="text-center">
                            {stateCounts["Uttarakhand"] || 0}
                          </td>
                        </tr>
                        <tr>
                          <td>36</td>
                          <td>West Bengal</td>
                          <td className="text-center">
                            {stateCounts["West Bengal"] || 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </main>

        {/* Footer */}
        <div className="footer mt-4">
          <div className="footer-py-15 footer-bar">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="text-sm-start">
                    <p className="mb-0">
                      © {currentYear + " "}TrackBee Community. All rights
                      reserved.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6 mt-2 mt-sm-0 pt-2 pt-sm-0 text-md-end">
                  <ul className="list-unstyled social-icon social mb-0">
                    {/* <li className="list-inline-item">
                      <Link to="/publicDashboard" className="rounded">
                        <Facebook className="fea icon-sm fea-social" />
                      </Link>
                    </li> */}
                    <li className="list-inline-item">
                      <Link to="https://twitter.com/TrackbeeSystem"  target="_blank" className="rounded">
                        <Twitter className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="https://www.linkedin.com/company/trackbee-data-solutions/"  target="_blank" className="rounded">
                        <Linkedin className="fea icon-sm fea-social" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#top"
            id="back-to-top"
            className="btn btn-icon btn-light back-to-top"
          >
            <ArrowUp className="icons"></ArrowUp>
          </a>
        </div>
      </div>
    </>
  );
};

export default MapView;
