import React, { useEffect, useState } from "react";
import TrackbeeCommunityLogo from "../assets/images/Trackbee community Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, REACT_APP_SECRET_KEY } from "./env";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";

const Login = () => {
  const navigate = useNavigate();
  // axios.defaults.withCredentials = true;

  const [cookies, setCookie] = useCookies(["token"]);
  // console.log(cookies);

  useEffect(() => {
    if (cookies.token) {
      // Redirect to dashboard if token exists
      navigate("/applicantDashboard");
    }
  }, [cookies.token, navigate]);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [pageErrors, setPageErrors] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.password) {
      validationErrors.password = "Please provide input";
    }
    if (!values.email) {
      validationErrors.email = "Please provide input";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      validationErrors.email = "Email is not valid";
    }

    setPageErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      axios
        .post(API_BASE_URL + "/auth/login", values)
        .then((res) => {
          if (res.data.loginStatus === true) {
            // Encrypt the email before storing it in the cookie
            const encryptedEmail = CryptoJS.AES.encrypt(
              values.email,
              REACT_APP_SECRET_KEY
            ).toString();
            setCookie("token", encryptedEmail, {
              path: "/",
              expires: new Date(Date.now() + 3600000),
            });
            navigate("/applicantDashboard");
          } else {
            setError(res.data.error);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <img
              src={TrackbeeCommunityLogo}
              width="150px"
              alt="TrackbeeCommunityLogo"
            />
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <label className="form-label">
                      <div className="text-danger">{error && error}</div>
                    </label>
                  </div>
                </div>
                <div>
                  {pageErrors.email && (
                    <div className="text-danger small mb-2">
                      {pageErrors.email}
                    </div>
                  )}
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control validation-require"
                      placeholder="Email"
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fas fa-envelope" />
                      </span>
                    </div>
                  </div>
                </div>
                <label className="form-label">
                  <span className="small font-noraml text-danger error_password" />
                </label>
                <div>
                  {pageErrors.password && (
                    <div className="text-danger small mb-2">
                      {pageErrors.password}
                    </div>
                  )}
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control validation-require"
                      placeholder="Password"
                      onChange={(e) =>
                        setValues({ ...values, password: e.target.value })
                      }
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fas fa-lock" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      id="sign_in"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
