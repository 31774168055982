import React from "react";
import logodark from "../assets/front_images/logo-dark.png";
import logolight from "../assets/front_images/logo-light.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (e, hash) => {
    e.preventDefault();
    navigate("/", { state: { hash } });
  };

  function toggleMenu() {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  }

  return (
    <header id="topnav" className="defaultscroll sticky">
      <div className="container">
        <Link className="logo" to="/">
          <img
            src={logodark}
            style={{ height: "70px" }}
            className="logo-light-mode"
            alt="logo-dark"
          />
          <img
            src={logolight}
            style={{ height: "70px" }}
            className="logo-dark-mode"
            alt="logo-light"
          />
        </Link>
        <div className="buy-button">
          <Link to="/profileForm" className="btn btn-primary">
            Apply Now
          </Link>
        </div>

        <div className="menu-extras">
          <div className="menu-item">
            {/* Mobile menu toggle*/}
            <a className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
            {/* End mobile menu toggle*/}
          </div>
        </div>

        <div id="navigation">
          <ul className="navigation-menu">
            {/* {location.pathname === "/publicDashboard" ||
            location.pathname === "/mapView" ? (
              <li style={{ marginRight: "0px" }}>
                <a href="/" className="sub-menu-item">
                  Home
                </a>
              </li>
            ) : (
              ""
            )} */}
            <li style={{ marginRight: "0px" }}>
              <a
                href="/#community"
                onClick={(e) => handleLinkClick(e, "#community")}
                className="sub-menu-item"
              >
                Community
              </a>
            </li>
            <li style={{ marginRight: "0px" }}>
              <a
                href="/#event"
                onClick={(e) => handleLinkClick(e, "#event")}
                className="sub-menu-item"
              >
                Event
              </a>
            </li>

            <li>
              <a
                href="/jobForEnumerators"
                className="sub-menu-item"
                style={{ marginRight: "0px" }}
              >
                Job Posts
              </a>
            </li>
            {location.pathname === "/publicDashboard" ? (
              <li>
                <a
                  href="/mapView"
                  className="sub-menu-item"
                  style={{ marginRight: "0px" }}
                >
                  Map View
                </a>
              </li>
            ) : (
              <li>
                <a
                  href="/publicDashboard"
                  className="sub-menu-item"
                  style={{ marginRight: "0px" }}
                >
                  Public Dashboard
                </a>
              </li>
            )}
            <li style={{ marginRight: "0px" }}>
              <a
                href="/#faq"
                onClick={(e) => handleLinkClick(e, "#faq")}
                className="sub-menu-item"
              >
                FAQ
              </a>
            </li>
            <li style={{ marginRight: "0px" }}>
              <a
                href="/#contact"
                onClick={(e) => handleLinkClick(e, "#contact")}
                className="sub-menu-item"
              >
                Contact
              </a>
            </li>
          </ul>
          <div className="buy-menu-btn d-none">
            <a href="/profileForm" className="btn btn-primary">
              Apply Now
            </a>
            {/* <div className="buy-menu-btn d-none">
              <a href="/" className="btn btn-outline-primary">
                Home
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
