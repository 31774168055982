import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation

const ThankYouMessage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>Thank You!</h1>
        <p style={styles.message}>
          Your information has been successfully submitted.
        </p>
        <p style={styles.message}>
          We appreciate your cooperation. If you have any further questions or
          concerns, feel free to contact us.
        </p>
        <Link
          to="/"
          style={styles.link}
          // Applying linkHovered style when hovered using inline style
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#4796c5")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#363B5F")}
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  content: {
    textAlign: "center",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#333",
  },
  message: {
    fontSize: "1.2rem",
    color: "#555",
    marginBottom: "10px",
  },
  link: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#363B5F",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
    fontSize: "1rem",
    marginTop: "20px",
    transition: "background-color 0.3s ease", // Adding transition for smooth color change
  },
};

export default ThankYouMessage;
