import React, { useEffect, useState } from "react";
import FooterOne from "./FooterOne";
import { Link, useNavigate, useParams } from "react-router-dom";
import logodark from "../assets/front_images/logo-dark.png";
import logolight from "../assets/front_images/logo-light.png";
import "../styles/Style.css";
import {
  Calendar,
  Mail,
  MessageCircle,
  MessageSquare,
  Phone,
} from "react-feather";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
// const API_BASE_URL = 'http://localhost:3000';
import {API_BASE_URL, REACT_APP_SECRET_KEY} from "./env";

const EditJobPosts = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    header: "",
    body: "",
    phone: "",
    email: "",
    posted_on: "",
    status: "", // Assuming this is for the radio button
  });
  const [errors, setErrors] = useState({});
  const [startDate, setStartDate] = useState(new Date());

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // For radio buttons, we update the state only if the button is checked
    if (type === "radio" && !checked) {
      return;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.header) {
      validationErrors.header = "Please provide input";
    }
    if (!values.body) {
      validationErrors.body = "Please provide input";
    }
    if (!values.posted_on) {
      validationErrors.posted_on = "Please provide input";
    }
    if (!values.status) {
      validationErrors.status = "Please provide input";
    }
    if (!values.email) {
      validationErrors.email = "Please provide input";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        values.email.trim()
      )
    ) {
      validationErrors.email = "Please provide valid Email id";
    }
    if (!values.phone) {
      validationErrors.phone = "Please provide input";
    } else if (!values.phone || !/^[6789]\d{9}$/.test(values.phone)) {
      validationErrors.phone = "Please provide a valid Mobile number";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      alert("Form Submitted successfully");
      // console.log(values);

      axios
        .put( API_BASE_URL + "/auth/alterJobPosts/" + id, values)
        .then((res) => {
          if (res.data.Status) {
            // console.log(res.data);
            // alert(
            //   "Thank you for applying. Our team will get back to you soon."
            // );
            // window.location.reload();
            navigate("/jobposts");
          } else {
            console.log(res.data.Error);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    axios
      .get( API_BASE_URL + "/auth/editJobPosts/" + id)
      .then((res) => {
        if (res.data.Status) {
          // console.log(res.data.Result[0]);
          setValues(res.data.Result[0]);
        } else {
          alert(res.data.Error);
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <div>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          <Link className="logo" to="/">
            <img
              src={logodark}
              style={{ height: "70px" }}
              className="logo-light-mode"
              alt="logo-dark"
            />
            <img
              src={logolight}
              style={{ height: "70px" }}
              className="logo-dark-mode"
              alt="logo-light"
            />
          </Link>
          <div className="buy-button">
            <Link to="/jobposts" className="btn btn-primary">
              Back
            </Link>
          </div>
        </div>
      </header>
      <div>
        <section className="bg-half bg-light d-table w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="page-next-level">
                <div className="page-next">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                      <ul
                        className="breadcrumb bg-white rounded shadow mb-0 custom-breadcrumb"
                        style={{ padding: "10px" }}
                      >
                        <li className="breadcrumb-item">
                          <a href="/">TrackBee Community</a>
                        </li>
                        <li className="breadcrumb-item active">Edit Job Post</li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-7">
                <div className="custom-form border-0" style={{ border: "0" }}>
                  <div className="card-body">
                    <form
                      className="rounded shadow p-4"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Header
                              <span className="text-danger">
                                *{" "}
                                {errors.header && (
                                  <span className="text-danger small">
                                    {errors.header}
                                  </span>
                                )}
                              </span>{" "}
                              <span className="small font-noraml text-danger error_header" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MessageSquare className="fea icon-sm icons" />
                              <textarea
                                name="header"
                                id="header"
                                rows={2}
                                value={values.header}
                                className="form-control ps-5 validation-require"
                                placeholder="Enter Header"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Body
                              <span className="text-danger">
                                *{" "}
                                {errors.body && (
                                  <span className="text-danger small">
                                    {errors.body}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_body" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <MessageCircle className="fea icon-sm icons" />
                              <textarea
                                name="body"
                                id="body"
                                rows={4}
                                value={values.body}
                                className="form-control ps-5 validation-require"
                                placeholder="Enter Body"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Contact Number
                              <span className="text-danger">
                                *{" "}
                                {errors.phone && (
                                  <span className="text-danger small">
                                    {errors.phone}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_phone_number" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Phone className="fea icon-sm icons" />
                              <input
                                name="phone"
                                id="applicant_phone_number"
                                className="form-control ps-5 "
                                placeholder="Enter mobile number"
                                maxLength={10}
                                autoComplete="off"
                                value={values.phone}
                                onChange={handleInputChange}
                                onKeyPress={(event) => {
                                  // Allow only numeric input (0-9) and prevent other characters
                                  const isValidInput = /[0-9]/.test(event.key);
                                  if (!isValidInput) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Email
                              <span className="text-danger">
                                *{" "}
                                {errors.email && (
                                  <span className="text-danger small">
                                    {errors.email}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_applicant_email" />
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Mail className="fea icon-sm icons" />
                              <input
                                name="email"
                                id="applicant_email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Enter email"
                                maxLength={200}
                                value={values.email}
                                autoComplete="off"
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Posted on
                              <span className="text-danger">
                                *{" "}
                                {errors.posted_on && (
                                  <span className="text-danger small">
                                    {errors.posted_on}
                                  </span>
                                )}
                              </span>
                            </label>
                            <div className="form-icon position-relative text-muted">
                              <Calendar className="fea icon-sm icons" />
                              <input
                                type="date"
                                name="posted_on"
                                className="form-control ps-5"
                                placeholder="Select Date"
                                value={values.posted_on}
                                onChange={(e) =>
                                  handleInputChange({
                                    target: {
                                      name: "posted_on",
                                      value: e.target.value,
                                    },
                                  })
                                }
                                min={new Date().toISOString().split("T")[0]} // Disable previous dates
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* Radio Input Field */}
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Status
                              <span className="text-danger">
                                *{" "}
                                {errors.status && (
                                  <span className="text-danger small">
                                    {errors.status}
                                  </span>
                                )}
                              </span>
                              <span className="small font-noraml text-danger error_Status" />
                            </label>
                            <div className="row">
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-2">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="status"
                                    id="status"
                                    value="1"
                                    checked={values.status === "1"}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Status_yes"
                                  >
                                    Active
                                  </label>
                                </div>
                              </div>
                              <div className="custom-control custom-radio text-muted custom-control-inline col-md-3">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input experience-validation"
                                    type="radio"
                                    name="status"
                                    id="status"
                                    value="0"
                                    checked={values.status === "0"}
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Status_no"
                                  >
                                    In Active
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-4 d-flex justify-content-center">
                          <button
                            id="membership_apply"
                            className="submitBnt btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FooterOne />
    </div>
  );
};

export default EditJobPosts;
